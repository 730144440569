import { createContext, useContext, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();


export const AuthProvider = ({ user, children, error }) => {

  const [isPartner, setPartner] = useState(user);
  const [isMember, setMember] = useState(user);
  const [isAdmin, setAdmin] = useState(user);
  const [userID, setUserID] = useState(user);
  const [email, setEmail] = useState(user);
  const [name, setName] = useState(user);


    const checkAuthStatus = async () => {
        await axios.get('/api/userInfo', { withCredentials: true })
        .then((response) => {
          // console.log(response.data);
            const { _id, email, name, level } = response.data;
            setPartner(level === "partner");
            setMember(level === "member");
            setAdmin(level === "admin");
            setName(name);
            setUserID(_id);
            setEmail(email);
        })
        .catch((e) => {
            console.log('Unauthorized access');
        });
    }    

  return <AuthContext.Provider value={{ isPartner, isMember, isAdmin, userID, email, name, checkAuthStatus }}>{children}</AuthContext.Provider>;

}

const useAuth = () => {
  const usingContext = useContext(AuthContext);
  return usingContext;
};

export default useAuth;