import { Navigate, Outlet } from 'react-router-dom'
import useAuth from './AuthContext';
import { useState } from 'react';

const PrivateRoutes = ({ isAllowed, redirectPath="/login", children }) => {
  const { isPartner, isMember, isAdmin } = useAuth();
  const [loading, setLoading] = useState();

  if (loading) {
    return <div>Loading...</div>
  }

  if (!((isAllowed === 1 && isPartner) || (isAllowed === 2 && (isMember || isAdmin)) || (isAllowed === 3 && isAdmin) || (isAllowed === 4 && (isPartner || isMember || isAdmin)))) {
    // console.log('Ciao', isAllowed, isAdmin);
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
}

export default PrivateRoutes;
