import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import useAuth from '../utils/AuthContext';
import { post } from '../utils/backRequests';

import bogo from '../images/bogogroup.png';
import quadrabar from '../images/Bande_color_2.png'

export default function Verif({ error, success }) {
    const [otp, setOtp] = useState();
    const [loading, setLoading] = useState(false);
    const { checkAuthStatus } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state.email;

    async function submitVerif(event) { 
        event.preventDefault();
        setLoading(true);

        // console.log("Clicked Verif");
        const response = await post('/login/email_verification/verify', { email, otp });
        const data = await response.json();

        if (data.verified) {
            sessionStorage.setItem('authToken', data.token);
            checkAuthStatus();
            setOtp("");
            success(response.status);
            setLoading(false);
            navigate('/home/recap');

        } else {
            error(data.error);
            setLoading(false);
        }
    }

    return(

    <div className='connection-page'>
        <img src={quadrabar} id='quadra'/>
        <div className='connection-content'>
            <img src={bogo} id="bogologo_connec" />
            <div className="login-wrapper">
                <h1 id='connection-title'>Authentification à double facteur</h1>
                <form autoComplete='off' id='login-form'>
                    <label>
                        <p id='input-title'>Code</p>
                        <input type="text" className='input' id='email' onChange={e => setOtp(e.target.value)}/>
                    </label>
                    <p className='parag-2fa'>Un code vous a été envoyé sur l’adresse e-mail associée à votre compte. Veuillez le renseigner dans le champ ci-dessus.</p>
                    <div>
                        {loading ? (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : (
                            <button onClick={submitVerif} id='connexion'>Valider</button>
                            )}
                    </div>
                </form>
            </div>
        </div>
    </div>
)};