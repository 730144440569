import { BrowserRouter, Navigate, Routes, Route, NavLink, useNavigate } from "react-router-dom";
import React from 'react';

import Account from './Account';
import BaseClients from './Base_clients';
import BasePartenaires from './Base_partenaires';
import Partner from './Partner_page';
import Admin from './Admin';
import Home_content from './Home_content';
import PrivateRoutes from './../utils/PrivateRoutes';
import useAuth from "../utils/AuthContext";
import Partner_page from "./Partner_page";
import Client_page from "./Client_page";

export default function Home({ error, success }) {

  const { isPartner, isMember, isAdmin } = useAuth();

  return (
    <>
      <menu>
        <img src="https://bogogroup.fr/wp-content/uploads/2022/11/BOGOGROUP-ALONE.png" id="bogologo"></img>
        <nav className="pages">
          {(isMember || isAdmin) && <div className="box_home" id="link_box" >
            <NavLink to="recap" id="link" style={({isActive}) => ({color: isActive ? "#FFFFFF" : "inherit", background: isActive ? "rgba(0, 0, 0, 0.6)" : "inherit"})} className={({isActive}) => `ln-${isActive ? ' active' : ''}`}>Accueil</NavLink>
          </div>}
          {(isMember || isAdmin) && <div className="box_clients" id="link_box">
            <NavLink to="base_clients" id="link" style={({isActive}) => ({color: isActive ? "#FFFFFF" : "inherit", background: isActive ? "rgba(0, 0, 0, 0.6)" : "inherit"})} className={({isActive}) => `ln-${isActive ? ' active' : ''}`}>Base Clients</NavLink>
          </div>}
          {(isMember || isAdmin) && <div className="box_parts" id="link_box">
            <NavLink to="base_partenaires" id="link" style={({isActive}) => ({color: isActive ? "#FFFFFF" : "inherit", background: isActive ? "rgba(0, 0, 0, 0.6)" : "inherit"})} className={({isActive}) => `ln-${isActive ? ' active' : ''}`}>Base Partenaires</NavLink>
          </div>}
          {(isMember || isAdmin) && <div className="box_studio" id="link_box">
            <a href='https://www.bogostudio.fr/log_admin.php' target='_blank' rel='noopener noreferrer' id="link">Bogo Studio</a>
          </div>}
          {isAdmin && <div className="box_admin" id="link_box">
            <NavLink to="admin" id="link" style={({isActive}) => ({color: isActive ? "#FFFFFF" : "inherit", background: isActive ? "rgba(0, 0, 0, 0.6)" : "inherit"})} className={({isActive}) => `ln-${isActive ? ' active' : ''}`}>Admin</NavLink>
          </div>}
          {isPartner && <div className="box_espace_part" id="link_box">
            <NavLink to="espace_partenaire" id="link" style={({isActive}) => ({color: isActive ? "#FFFFFF" : "inherit", background: isActive ? "rgba(0, 0, 0, 0.6)" : "inherit"})} className={({isActive}) => `ln-${isActive ? ' active' : ''}`}>Espace Partenaire</NavLink>
          </div>}
        </nav>
      </menu>
      <div className="content">
        <Account className="account" error={error} success={success}/>
        <div className="home">
        </div>
        <Routes>
          <Route element={<PrivateRoutes isAllowed={4} />}>
            <Route path='/account' element={<Account error={error} success={success} />} />
          </Route>
          <Route element={<PrivateRoutes isAllowed={2} />}>
            <Route path='/home' element={<Home error={error} success={success} />} />
            <Route path='/recap' element={<Home_content error={error} success={success} />} />
            <Route path='/base_clients' element={<BaseClients error={error} success={success} />} />
            <Route path='/base_partenaires' element={<BasePartenaires error={error} success={success} />} />
            <Route path='/base_partenaires/:id' element={<Partner_page error={error} success={success} />} />
            <Route path='/base_clients/:id' element={<Client_page error={error} success={success} />} />
          </Route>
          <Route element={<PrivateRoutes isAllowed={3} />}>
            <Route path='/admin' element={<Admin error={error} success={success} />} />
          </Route>
          <Route element={<PrivateRoutes isAllowed={1} />}>
            <Route path='/espace_partenaire' element={<Partner error={error} success={success} />} />
          </Route>
        </Routes>
      </div>
    </>  
  )
}