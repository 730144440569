import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Button from '@mui/material/Button';
import ActionCreate from "./ActionCreate";
import History from "./History";
import { Buffer } from 'buffer';
import Modal from '@mui/material/Modal';
import closing_arrow from "../images/closing_arrow_down.png";
import NotifBad from "./utils/NotifBad";
import NotifGood from "./utils/NotifGood";
import ContactCreate from "./ContactCreate";
import ActionsDetails from "./ActionsDetails";
import OperationsDetails from "./OperationsDetails";
import OperationCreate from "./OperationCreate";

export default function Client_page({ error, success }) {
    const [ client, setClient ] = useState(null);
    const [ clientSave, setClientSave ] = useState(null);
    const [ search, setSearch ] = useState('');
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ isEditableNotes, setIsEditableNotes] = useState(false);
    const [ isEditableContact, setIsEditableContact ] = useState(null);
    const [ openAddContact, setOpenAddContact ] = useState(false);
    const [ confirmDeleteContact, setConfirmDeleteContact ] = useState(false);
    const [ showCreateAction, setShowCreateAction ] = useState(false);
    const [ showCreateOp, setShowCreateOp ] = useState(false);
    const [ actions, setActions ] = useState([]);
    const [ showHistory, setShowHistory ] = useState(false);
    const [ image, setImage ] = useState(null);
    const [ infoOpen, setInfoOpen ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ operations, setOperations ] = useState([]);
    const [ opsTimeline, setOpsTimeline ] = useState(0);
    const [ openActionsDetails, setOpenActionsDetails ] = useState(null);
    const [ showOpsDetails, setShowOpsDetails ] = useState(null);
    const location = useLocation();
    const currentUrl = location.pathname;

    useEffect(() => {
        const fetchClientDetails = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                await axios.get(`/api/getClient/${id}`)
                    .then((response) => {
                        setClient(response.data);
                        const imageBuffer = Buffer.from(response.data.Image.data.data)
                        const imageEncoded = imageBuffer.toString('base64');
                        const imageData = atob(imageEncoded);
                        setImage(imageData);
                        setIsLoaded(true);
                    })
            } catch (e) {
                error(e.error);
            }
        };

        const fetchFutureActions = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                const response = await axios.get(`/api/getFutureActionsFor/${id}`);
                response.data.forEach(action => {
                    const date = new Date(action.Date);
                    action.Date = date;
                });
                setActions(response.data);
            } catch (e) {
                error(e.error);
            }
        };

        const fetchCurrentOps = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                const response = await axios.get(`/api/getCurrentOpsForClient/${id}`)
                .then((response) => {
                    response.data.forEach(operation => {
                        const date = new Date(operation["Date de fin"]);
                        operation["Date de fin"] = date;
                    });
                    setOperations(response.data);
                })
                .catch((e) => {
                    setErrorMessage(e.response.error);
                })
            } catch (e) {
                if (!errorMessage)
                    setErrorMessage(e.message);
                error(errorMessage)
            }
        };

        const fetchFutureOps = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                const response = await axios.get(`/api/getFutureOpsForClient/${id}`);
                response.data.forEach(operation => {
                    const date = new Date(operation["Date de lancement"]);
                    operation["Date de lancement"] = date;
                });
                setOperations(response.data);
            } catch (e) {
                error(e.error);
            }
        };

        const fetchPastOps = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                const response = await axios.get(`/api/getPastOpsForClient/${id}`);
                setOperations(response.data);
            } catch (e) {
                error(e.error);
            }
        };

        fetchFutureActions();
        if (!isEditable) {
            fetchClientDetails();
        }

        if (opsTimeline === 0) {
            fetchCurrentOps();
        } else if (opsTimeline === 1) {
            fetchFutureOps();
        } else if (opsTimeline === -1) {
            fetchPastOps();
        }

    }, [image, opsTimeline, isEditableContact, showCreateOp, openActionsDetails]);

    const handleModify = () => { setClientSave(client); setIsEditable(true) }
    const handleModifyContact = (e) => { setClientSave(client); setIsEditableContact(e.target.id) }
    const handleModifyCancel = () => { setClient(clientSave); setIsEditable(false) }
    const handleModifyCancelContact = () => { setClient(clientSave); setIsEditableContact(null) }
    const handleInfoModify = () => { setIsEditableNotes(true) }
    const handleInfoCancel = () => { setIsEditableNotes(false) }
    const handleShowAddContact = () => { setOpenAddContact(true) }
    const handleActionCreateShow = () => { setShowCreateAction(true) }
    const handleActionCreateClose = () => { setShowCreateAction(false) }
    const handleShowHistory = () => { setOpenActionsDetails(null); setShowHistory(true) }
    const handleHideHistory = () => { setShowHistory(false) }
    const handleInfoOpen = () => { setInfoOpen(true) }
    const handleInfoClose = () => { if (isEditableNotes) { setIsEditableNotes(false) }; setInfoOpen(false) }
    const handleActionDetailsClose = () => { setOpenActionsDetails(null) }
    const handleCreateOpOpen = async () => { setShowCreateOp(true) };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClient({ ...client, [name]: value });
    };

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        const contactId = isEditableContact;
        const contactIndex = client.Contacts.findIndex((contact) => contact._id === contactId);
        const newContacts = client.Contacts;
        newContacts[contactIndex][name] = value;
        setClient({ ...client, Contacts: newContacts });
    };

    const handleModifySave = async () => {
        try {
            await axios.post('/api/updateClient', client)
            .then((response) => {
                setIsEditableContact(null);
                setIsEditable(false);
                setIsEditableNotes(false);
                success(response.status);
            })
            .catch((e) => {
                error(e.error);
            })
        } catch (e) {
            error(e.error);
        }
    };

    const handleDeleteContact = async (e) => {
        try {
            const contactId = e.target.id;
            const contactIndex = client.Contacts.findIndex((contact) => contact._id === contactId);
            const newContacts = client.Contacts;
            newContacts.splice(contactIndex, 1);
            setClient({ ...client, Contacts: newContacts });

            await axios.post('/api/updateClient', client)
            .then((response) => {
                success(208);
            })
            .catch((e) => {
                error(e.error);
            })                

        } catch (e) {
            error(e.error);
        }
    };    

    const handleLogoClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
          const reader = new FileReader();
    
          reader.onloadend = async () => {
            setImage(reader.result);
            try {
                const formData = new FormData();
                formData.append('image', file);
                formData.append('id', client._id);
                // Send the image to the server endpoint
                const response = await axios.post('/api/uploadClientPhoto', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
      
            } catch (e) {
                error(e.error);
            }
          };
    
          reader.readAsDataURL(file);
        }
    };

    const handleSetPresent = () => {
        setOpsTimeline(0);

        document.getElementById('operations_future').style.backgroundColor = "#FFF";
        document.getElementById('operations_future').style.color = "#000";
        document.getElementById('operations_past').style.backgroundColor = "#FFF";
        document.getElementById('operations_past').style.color = "#000";

        document.getElementById('operations_present').style.backgroundColor = "#666666";
        document.getElementById('operations_present').style.setProperty('color', '#FFF', 'important');
    };

    const handleSetFuture = () => {
        setOpsTimeline(1);

        document.getElementById('operations_present').style.backgroundColor = "#FFF";
        document.getElementById('operations_present').style.setProperty('color', '#666', 'important');
        document.getElementById('operations_past').style.backgroundColor = "#FFF";
        document.getElementById('operations_past').style.color = "#000";

        document.getElementById('operations_future').style.backgroundColor = "#666666";
        document.getElementById('operations_future').style.setProperty('color', '#FFF', 'important');
    };

    const handleSetPast = () => {
        setOpsTimeline(-1);

        document.getElementById('operations_future').style.backgroundColor = "#FFF";
        document.getElementById('operations_future').style.color = "#000";
        document.getElementById('operations_present').style.backgroundColor = "#FFF";
        document.getElementById('operations_present').style.setProperty('color', '#666', 'important');

        document.getElementById('operations_past').style.backgroundColor = "#666666";
        document.getElementById('operations_past').style.setProperty('color', '#FFF', 'important');
    };

    async function handleAddContact(contact) {
        try {
            const newContacts = client.Contacts;
            newContacts.push(contact);
            setClient({ ...client, Contacts: newContacts });
            
            await axios.post('/api/updateClient', client)
            .then((response) => {
                setOpenAddContact(false);
                success(response.status);
            })
            .catch((e) => {
                error(e.error);
            })                

        } catch (e) {
            error(e.error);
        }
    };

    function openAction(actionId) {
        setShowHistory(false);
        setOpenActionsDetails(actionId);
    };

    return (
        <>
        {isLoaded ? (
        <div className="partner_page">
            {isLoaded && <div className="details_title">
                <h1 id="details_title_name">{client.Société}</h1>
                <hr/>
                <h3 className="undertitle">{client["Descriptif du partenaire"]}</h3>
            </div> }
            <div className="details_info">
                <div className="details_info_subtitle">
                    <h2 className="details_contact_subtitle">Informations</h2>
                    <hr/>
                    <h3 onClick={handleModify} className="undertitle">Modifier</h3>
                </div>
                {isLoaded && <div className="wrapper" id="details_info_content">
                    <div className="details_info_content_top">
                        <div className="details_info_content_left">
                            <label>
                                Adresse :
                                <input 
                                    type="text" 
                                    name="Adresse"
                                    value={client.Adresse}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Code postal :
                                <input 
                                    type="text" 
                                    name="Code postal"
                                    value={client["Code postal"]}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Ville :
                                <input 
                                    type="text" 
                                    name="Ville"
                                    value={client.Ville}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Région :
                                <input 
                                    type="text" 
                                    name="Région"
                                    value={client.Région}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Pays :
                                <input 
                                    type="text" 
                                    name="Pays"
                                    value={client.Pays}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                        </div>
                        <div className="details_info_content_right">
                        <label>
                                Tel. fixe :
                                <input 
                                    type="text" 
                                    name="Téléphone fixe"
                                    value={client["Téléphone fixe"]}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Email accueil :
                                <input 
                                    type="text" 
                                    name="Email_accueil"
                                    value={client["Email_accueil"]}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <div id="details_info_buttons">
                                { isEditable ?
                                <label>
                                    Site web :
                                    <input 
                                        type="text" 
                                        name="Site web"
                                        value={client["Site web"]}
                                        onChange={handleInputChange}
                                        disabled={!isEditable}
                                        className="input-modif"
                                    />
                                </label>
                                : <a href={client["Site web"]} className="partner-website-link">Site web</a> }
                                {!isEditable && <label id="more_partner_info" onClick={handleInfoOpen}>Plus d'infos</label>}
                            </div>
                            <Modal
                                open={infoOpen}
                                onClose={handleInfoClose}
                            >
                                <div className="wrapper" id="partner_info_modal">
                                    <img src={closing_arrow} onClick={handleInfoClose} id="closing_arrow_info_modal"/>
                                    <div className="partner_info_modal_content">
                                        <div className="partner_info_modal_titles">
                                            <div className="partner_info_modal_title">
                                                <h1>Notes sur le partenaire</h1>
                                                <hr/>
                                            </div>
                                            <h3 className="undertitle" onClick={handleInfoModify}>Modifier</h3>
                                        </div>
                                        <div className="partner_info_modal_text">
                                            <textarea 
                                            rows="15"
                                            type="text" 
                                            name="Notes sur la société"
                                            value={client["Notes sur la société"]}
                                            onChange={handleInputChange}
                                            disabled={!isEditableNotes}
                                            className="input-modif"
                                            id="partner_info_modal_textarea"
                                            />
                                        </div>
                                        <div className="partner_info_modal_buttons">
                                            { isEditableNotes && <Button className="cancel" onClick={handleInfoCancel}>Annuler</Button> }
                                            { isEditableNotes && <Button className="save" id="save_partner_info" onClick={handleModifySave}>Enregistrer</Button> }
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        { isLoaded && <div className="wrapper" id="details_info_content_logo" onClick={handleLogoClick}>
                            {image ? (
                                <img src={`data:image/png;base64,${image}`} alt="Uploaded" className="uploaded-image" />
                            ) : (
                                <p>Click to upload an image</p>
                            )}
                            <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </div> }
                    </div>
                    <div className="details_info_content_bottom">
                        {isEditable && <Button className="cancel" id="cancel-modif-partner" onClick={handleModifyCancel}>Annuler</Button>}
                        {isEditable && <Button className="save" id="save-modif-partner" onClick={handleModifySave}>Enregistrer</Button>}                        
                    </div>
                </div> }
            </div>
            <div className="details_contact">
                <div>
                    <h2 className="details_contact_subtitle">Contacts</h2>
                    <hr/>
                    <h3 onClick={handleShowAddContact} className="undertitle" id="add_contact_button">Ajouter un contact</h3>
                </div>
                <div className="details_contact_content">
                    { isLoaded && <ul className="contacts_list">
                        {client.Contacts.map((contact) => (
                            <div className="wrapper contact_card" id={contact._id} key={contact._id}>
                                <div className="contact_card_left">
                                    <label>
                                        Civilité :
                                        <input 
                                            type="text" 
                                            name="Civilité"
                                            value={contact["Civilité"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Nom :
                                        <input 
                                            type="text" 
                                            name="Nom du responsable"
                                            value={contact["Nom"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Prénom :
                                        <input 
                                            type="text" 
                                            name="Prénom du responsable"
                                            value={contact["Prénom"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Tel. fixe :
                                        <input 
                                            type="text" 
                                            name="Téléphone fixe"
                                            value={contact["Téléphone fixe"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Portable :
                                        <input 
                                            type="text" 
                                            name="Portable"
                                            value={contact["Portable"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Email :
                                        <input 
                                            type="text" 
                                            name="Email"
                                            value={contact["Email"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                </div>
                                <div className="contact_card_right">
                                    <label>
                                        Fonction :
                                        <input 
                                            type="text" 
                                            name="Fonction"
                                            value={contact["Fonction"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <div className="contact_card_buttons">
                                        { isEditableContact !== contact._id ? <Button className="save save_contact" id={contact._id} onClick={handleModifyContact}>Modifier</Button> : <Button className="cancel" onClick={handleModifyCancelContact}>Annuler</Button> }
                                        { isEditableContact !== contact._id ? <Button className="cancel delete_contact" onClick={() => setConfirmDeleteContact(true)}>Supprimer</Button> : <Button className="save delete_contact" onClick={handleModifySave}>Enregistrer</Button> }
                                    </div>
                                    <Modal open={confirmDeleteContact}>
                                        <div className="wrapper" id="confirm_delete_contact_modal">
                                            <h2>Voulez-vous vraiment supprimer ce contact ?</h2>
                                            <div className="confirm-delete-buttons">
                                                <Button className="wrapper confirm-delete-contact-button" onClick={() => setConfirmDeleteContact(false)}>Annuler</Button>
                                                <Button className="cancel" onClick={handleDeleteContact} id={contact._id}>Supprimer</Button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        ))}
                    </ul>}
                </div>
            </div>
            <div className="details_actions">
                <div className="details_actions_subtitle">
                    <h2 className="details_contact_subtitle">Actions</h2>
                    <hr/>
                    <div className="details_actions_subtitle_buttons">
                        <h3 className="undertitle" onClick={handleActionCreateShow}>Créer une action</h3>
                        <h3 className="undertitle" onClick={handleShowHistory}>Historique</h3>
                    </div>
                </div>
                <div className="details_actions_content">
                    { isLoaded && <ul className="actions_list">
                        {actions.map((action) => (
                            <div className="wrapper" id="action_card" onClick={() => { setShowHistory(false); setOpenActionsDetails(action._id) }} key={action._id}>
                                <h2 id="action_card_title">{action.Nom}</h2>
                                <div className="action_card_infos">
                                    <div className="action_card_left">
                                        <div id="action_date">
                                            Date : {action.Date.getDate()}/{action.Date.getMonth() + 1}/{action.Date.getFullYear()}
                                        </div>
                                        <div id="action_hour">
                                            Heure : {action.Date.getHours()}h{action.Date.getMinutes() === 0 ? '00' : action.Date.getMinutes()}
                                        </div>
                                    </div>
                                    <div className="action_card_right">
                                        <div id="action_contact">
                                            Contact : {action.Contact}
                                        </div>
                                        <div id="action_operations">
                                            {action.Opérations.map((operation) => (
                                                <div className="action_operation" key={operation._id}>
                                                    Opération : {operation.nom}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ul>}
                    { openActionsDetails && <ActionsDetails id={openActionsDetails} onClose={handleActionDetailsClose} partnerSide={false} error={error} success={success}/> }
                </div>
            </div>
            <div className="details_operations">
                <div className="details_operations_subtitle">
                    <h2 className="details_contact_subtitle">Opérations</h2>
                    <hr/>
                    <div className="details_actions_subtitle_buttons">
                        <h3 className="undertitle" onClick={handleCreateOpOpen}>Créer une opération</h3>
                    </div>
                </div>
                <div className="details_operations_buttons">
                    <div className="details_operations_buttons_left">
                        <Button className="operations_time_buttons" id='operations_present' onClick={handleSetPresent}>En cours</Button>
                        <Button className="operations_time_buttons" id='operations_future' onClick={handleSetFuture}>Futures</Button>
                        <Button className="operations_time_buttons" id='operations_past' onClick={handleSetPast}>Passées</Button>
                    </div>
                    <input className='searchbar_base' id="searchbar_operations" type='text' placeholder='Recherche rapide' onChange={(e) => setSearch(e.target.value)} value={search}/>
                </div>
                <div className="wrapper" id="details_operations_content">
                    <ul className="operations_list">
                        {operations.filter((operation) => {
                            if (search === "") {
                                return operation;
                            } else if (operation.Nom.toLowerCase().includes(search.toLowerCase())) {
                                return operation;
                            }
                        }).map((operation) => (
                            <div className="wrapper operation_card" onClick={() => setShowOpsDetails(operation._id)} key={operation._id}>
                                <h2 className="operation_card_title">{operation.Nom}</h2>
                                <h4 id="operation_card_date">{operation["Date de fin"] ? `Date de fin : ${operation["Date de fin"].getDate()}/${operation["Date de fin"].getMonth() + 1}` : operation["Date de lancement"] ? `Date de lancement : ${operation["Date de lancement"].getDate()}/${operation["Date de lancement"].getMonth() + 1}` : ""}</h4>
                            </div>
                        ))}
                    </ul>
                </div>
            </div>
            { openAddContact && <ContactCreate onClose={() => setOpenAddContact(false)} onValidate={handleAddContact} error={error} success={success} /> }
            { showCreateAction && <ActionCreate
                                    onClose={handleActionCreateClose}
                                    onValidate={handleActionCreateClose}
                                    partnerId={client._id}
                                    partnerSide={false}
                                    error={error} success={success}
                                    /> }
            { showHistory && <History onClose={handleHideHistory} partnerId={client._id} openAction={openAction} error={error} success={success} />}
                </div> ) : (<h1 id="loading-partner">Chargement...</h1>) }
            { showOpsDetails && <OperationsDetails partnerSide={false} id={showOpsDetails} partnerId={client._id} onClose={() => setShowOpsDetails(null)} error={error} success={success} />}
            { showCreateOp && <OperationCreate onClose={() => setShowCreateOp(false)} partnerId={client._id} error={error} success={success} /> }
        </>
    );
};
