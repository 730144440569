import React, { useState, useEffect } from 'react'
import axios from 'axios';
import closing_arrow from '../images/closing_arrow_down.png';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

export default function OperationsDetails({ partnerSide, id, partnerId, onClose, error, success }) {
    const [ operation, setOperation ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ selectedDate, setSelectedDate ] = useState(null);
    const [ selectedDate1, setSelectedDate1 ] = useState(null);
    const [ stage, setStage ] = useState();
    const [ showPartnersList, setShowPartnersList ] = useState(false);
    const [ partnersList, setPartnersList ] = useState([]);

    useEffect(() => {
        const fetchOpsDetails = async () => {
            await axios.get(`/api/getOperation/${id}`)
            .then(response => {
                const date = new Date(response.data["Date de lancement"]);
                response.data["Date de lancement"] = date;
                const date1 = new Date(response.data["Date de fin"]);
                response.data["Date de fin"] = date1;
                setOperation(response.data);
                setLoading(false);
            })
            .catch(err => error(err.error));
        };

        const fetchStage = async () => {
            await axios.get(`/api/getStage?id1=${id}&id2=${partnerId}`)
            .then(response => {
                setStage(response.data.stade);
            })
            .catch(err => error(err.error));
        };

        if (partnerSide) {
            fetchStage();
        }
        fetchOpsDetails();

    }, [isEditable, stage]);

    const handleInputChange = (e) => {
        setOperation({
            ...operation,
            [e.target.name]: e.target.value
        });
    };
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    };
    
    const handleSave = async () => {
        await axios.post(`/api/updateOperation`, operation)
        .then(response => {
            setIsEditable(false);
        })
        .catch(err => error(err.error));
    };

    const handleChangeStage = async (e) => {
        await axios.post(`/api/updateStage`, { id1: id, id2: partnerId, stade: e.target.value })
        .then(response => {
            setStage(response.data.stade);
        })
        .catch(err => error(err.error));
    };

    const handleDisplayPartners = () => {
        axios.get(`/api/getPartnersList/${id}`)
        .then(response => {
            setPartnersList(response.data);
        })
        .catch(err => error(err.error));
        setShowPartnersList(true);
    };

  return (
    <>
    { !loading ? (<div className='wrapper operations_details'>
        <img src={closing_arrow} className='close-ops-details' onClick={onClose}/>
        <div>
            <h2 className='operations-details-title'>{operation.Nom}</h2>
            <hr />
            <div className='ops-details-undertitles'>
                { !isEditable ? (<h3 className='undertitle' onClick={() => setIsEditable(true)}>Modifier</h3>) : (<h3 className='undertitle' onClick={handleSave}>Enregistrer</h3>) }
                { !partnerSide && <h3 className='undertitle' onClick={handleDisplayPartners}>Voir la liste des partenaires</h3>}
            </div>
            <Modal open={showPartnersList}>
                <div className='wrapper display-participating'>
                    <div className='display-participating-wrapper'>
                        <ul className='display-participating-list'>
                            { partnersList.map(partner => (
                                <>
                                    <h2 className='display-participating-item'>{partner.nom}</h2>
                                    <hr className='long-hr'/>
                                </>
                                )
                            )}
                        </ul>
                    </div>
                    <Button className="cancel" onClick={() => setShowPartnersList(false)}>Fermer</Button>
                </div>
            </Modal>
        </div>
        <textarea rows="8" className='input-modif ops-details-notes' disabled={!isEditable} name='Notes' value={operation.Notes} onChange={handleInputChange} />
        <div className='ops-details-body'>
            <div className='ops-details-budget'>
                <h2 className='ops-details-budget-label'>Budget : €</h2>
                <input className='input-modif ops-details-budget-content' disabled={!isEditable} name='Budget' value={operation.Budget} onChange={handleInputChange} />
            </div>
            <div className='ops-details-dates'>
                <h2 className='ops-details-dates-label'>Date de début :</h2>
                { !isEditable ? (<span className='ops-details-dates-content'>{operation["Date de lancement"].getDate()}/{operation["Date de lancement"].getMonth() + 1}</span>) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker id="pickDateModify" value={selectedDate} onChange={handleDateChange} />
                    </LocalizationProvider>
                )}
            </div>
            <div className='ops-details-dates'>
            <h2 className='ops-details-dates-label'>Date de début :</h2>
                { !isEditable ? (<span className='ops-details-dates-content'>{operation["Date de fin"].getDate()}/{operation["Date de fin"].getMonth() + 1}</span>) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker id="pickDateModify" value={selectedDate1} onChange={handleDateChange1} />
                    </LocalizationProvider>
                )}
            </div>
            <div>
                { partnerSide ? (<h2>Passer le partenaire au stade :</h2>) : (<h2>Relancer les partenaires :</h2>) }
                <div className='ops-details-buttons'>
                    { !(stage === 1) ? (<Button className='cancel ops-details-button' value={1} onClick={handleChangeStage}>Stade 1</Button>) : (<Button className='cancel cannot-be-pressed'>Stade 1</Button>) }
                    { !(stage === 2) ? (<Button className='cancel ops-details-button yellow-button' value={2} onClick={handleChangeStage}>Stade 2</Button>) : (<Button className='cancel cannot-be-pressed yellow-button'>Stade 2</Button>) }
                    { !(stage === 3) ? (<Button className='save ops-details-button' value={3} onClick={handleChangeStage}>Stade 3</Button>) : (<Button className='save cannot-be-pressed'>Stade 3</Button>) }
                </div>
            </div>
        </div>
    </div>) : (<div>Chargement...</div>) }
    </>
  )
}