import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import axios from 'axios';
import closing_arrow from '../images/closing_arrow.png';
import Modal from '@mui/material/Modal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function ActionsDetails({ id , onClose, partnerSide, error, success }) {
    const [action, setAction] = useState({});
    const [stage, setStage] = useState('');
    const [loading, setLoading] = useState(true);
    const [modifySubject, setModifySubject] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [modifyDate, setModifyDate] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {

        const fetchActionsDetails = async () => {
            await axios.get(`/api/getAction/${id}`)
            .then(response => {
                const date = new Date(response.data.Date);
                response.data.Date = date;
                setAction(response.data);

                if (partnerSide) {
                    axios.get(`/api/getStage?id1=${response.data.Opérations[0]["opération"]}&id2=${response.data.Entité}`)
                    .then(res => {
                        setStage(res.data.stade);
                    })
                    .catch(err => {
                        error("Une erreur est survenue lors de la récupération des informations de l'action. Rechargez la page. Si ce problème persiste, contactez le support.");
                    
                    });
                }
            })
            .catch(err => {
                error("Une erreur est survenue lors de la récupération des informations de l'action. Rechargez la page. Si ce problème persiste, contactez le support.");
            });

            setLoading(false);
        };

        fetchActionsDetails();
    }, [modifySubject, stage]);

    const handleOpenDeleteConfirm = () => { setDeleteConfirm(true); };

    const handleSaveSubject = async () => {
        await axios.post(`/api/updateAction`, action)
        .then(response => {
            setModifySubject(false);
            setModifyDate(false);
            success(response.status);
        })
        .catch(err => {
            err(err.error);
        });
    };

    const handleSaveDate = async () => {
        setAction({
            ...action,
            Date: selectedDate
        });
        await axios.post(`/api/updateAction`, action)
        .then(response => {
            setModifyDate(false);
            setModifySubject(false);
            success(response.status);
        })
        .catch(err => {
            error(err.error);
        });
    };

    const handleInputChange = (e) => {
        setAction({
            ...action,
            [e.target.name]: e.target.value
        });
    };

    const handleDelete = async () => {
        await axios.delete(`/api/deleteAction/${id}`)
        .then(response => {
            setDeleteConfirm(false);
            onClose();
        })
        .catch(err => error(err.error));
    };

    const handleDateChange = (date) => {
        setSelectedDate(new Date(date));
    };

    const handleUpgradeStage = async () => {
        await axios.post(`/api/updateStage`, { id1: action.Opérations[0]["opération"], id2: action.Entité, stade: stage + 1 })
        .then(response => {
            setStage(stage + 1);
        })
        .catch(err => error(err.error));
    };

  return (
    <div className="wrapper details_action_wrapper">
        { !loading ? (<div className='action-detais-wrapper2'>
            <div className="details_action_title_wrapper">
                <h1 id="create_action_title">{action.Nom}</h1>
                <hr className='details_action_hr'/>
            </div>
            <div className="details_action_wrap">
                <img src={closing_arrow} alt="close" id="close-history" onClick={onClose}/>
                <div className='details_action_body'>
                    <div>
                        <div className='action-details-field'>
                            <h2>Date : </h2>
                            <span className='action-details-big'>{action.Date.getDate()}/{action.Date.getMonth() + 1}/{action.Date.getFullYear()}</span>
                            <h3 className='undertitle action-details-modif' onClick={() => setModifyDate(true)}>Modifier</h3>
                        </div>
                        <div className='action-details-field'>
                            <h2>Heure : </h2>
                            <span className='action-details-big'>{action.Date.getHours()}h{action.Date.getMinutes() === 0 ? '00' : action.Date.getMinutes()}</span>
                            <h3 className='undertitle action-details-modif' onClick={() => setModifyDate(true)}>Modifier</h3>
                        </div>
                    </div>
                    <div>
                        <div className='action-details-field'>
                            <h2>Contact : </h2>
                            <span className='action-details-big'>{action.Contact}</span>
                        </div>
                        <div className='action-details-field'>
                            <h2>Opération : </h2>
                            <span className='action-details-big'>{action.Opérations[0].nom}</span>
                        </div>
                        <div className='action-details-field action-details-subject'>
                            <div className='action-details-subject-title'>
                                <h2 className='action-details-subject-label'>Sujet : </h2>
                                { !modifySubject ? (<h3 className='undertitle action-details-modif' onClick={() => setModifySubject(true)}>Modifier</h3>) : (<h3 className='undertitle action-details-modif' onClick={handleSaveSubject}>Enregistrer</h3>) }
                            </div>
                            <textarea rows="6" disabled={!modifySubject} className='action-details-big action-details-smaller input-modif' value={action.Notes} onChange={handleInputChange} name='Notes' />
                        </div>
                        { partnerSide && <div className='action-details-field2'>
                                <h2 className='action-details-stage-title'>Stade actuel du</h2>
                            <div className='whole-width'>
                                <h2> partenaire : </h2>
                                <h2 className='stage-in-action'>{stage}</h2>
                            </div>
                        </div> }
                    </div>
                </div>
            </div>
            <div id="details_action_buttons">
                <Button className="save" id="upload_details_action" onClick={onClose}>Téléverser un compte-rendu</Button>
                { partnerSide && !(stage === 3) && <Button className="save" id="next_stage_details_action" onClick={handleUpgradeStage}>Passer au stade suivant</Button> }
                <Button className="cancel" id="delete_details_action" onClick={handleOpenDeleteConfirm}>Supprimer l'action</Button>
            </div>
            <Modal open={deleteConfirm}>
                <div className="wrapper" id="confirm_delete_contact_modal">
                    <h2>Voulez-vous vraiment supprimer cette action ?</h2>
                    <div className="confirm-delete-buttons">
                        <Button className="wrapper confirm-delete-contact-button" onClick={() => setDeleteConfirm(false)}>Annuler</Button>
                        <Button className="cancel" onClick={handleDelete}>Supprimer</Button>
                    </div>
                </div>
            </Modal>
            <Modal open={modifyDate}>
                <div className='wrapper modify_date_modal'>
                    <h2>Modifier la date</h2>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker id="pickDateModify" value={selectedDate} onChange={handleDateChange} />
                    </LocalizationProvider>
                    <div className='modify-date-buttons'>
                        <Button className='cancel modify-date-button1' onClick={() => setModifyDate(false)}>Annuler</Button>
                        <Button className='save modify-date-button2' onClick={handleSaveDate}>Enregistrer</Button>
                    </div>
                </div>
            </Modal>
        </div>)
        : (<div className='action-details-loading'>
            <h1 className='actions-details-loading-text'>Chargement...</h1>
        </div>)}
    </div>
    )
}