import React, { useState, useEffect } from "react";
import axios from "axios";
import closing_arrow from "../images/closing_arrow.png";
import NotifBad from "./utils/NotifBad";

export default function History({ onClose, partnerId, openAction, error }) {
    const [history, setHistory] = useState([]);

    useEffect(() => {
        const fetchHistory = async () => {
            await axios.get(`/api/getActionsHistoryFor/${partnerId}`)
            .then((response) => {
                response.data.forEach(action => {
                    const date = new Date(action.Date);
                    action.Date = date;
                });
                setHistory(response.data);
            })
            .catch((err) => {
                error(err.error);
            });
        }

        fetchHistory();
    }, []);

    return(
        <div className="wrapper" id="history">
            <div id="history-header">
                <h2>Historique</h2>
                <hr id="history-underline"/>
            </div>
            <div id="history-body">
                <img src={closing_arrow} alt="close" id="close-history" onClick={onClose}/>
                <ul id="history-list">
                    {history.map((action) => (
                        <div className="wrapper" id="history-item" onClick={() => openAction(action._id)}>
                            <h2 className="history-item-title">{action.Nom}</h2>
                            <div className="history-item-content">
                                <p className="history-item-operation">Opération : {action.Opérations[0].nom}</p>
                                <p className="history-item-date">Date : {action.Date.toLocaleDateString()}</p>
                            </div>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    )
}