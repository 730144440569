import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import useAuth from '../utils/AuthContext';
import { post } from '../utils/backRequests';

export default function Reset({ error, success }) {
    const [otp, setOtp] = useState();
    const [newPassword, setNewPassword] = useState();
    const [newPasswordConfirm, setNewPasswordConfirm] = useState();
    const [loading, setLoading] = useState(false);
    const [isLogged, setIsLogged] = useState();
    const [email_forgot, setEmailForgot] = useState();
    const { userID, email } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const handleOTPInput = (e) => setOtp(e.target.value);
    const handlePwdInput = (e) => setNewPassword(e.target.value);
    const handleConfirmInput = (e) => setNewPasswordConfirm(e.target.value);

    async function submitReset(event) {
        event.preventDefault();
        setLoading(true);
        
        const givenEmail = location.state.isLogged ? email : location.state.email_forgot;

        const response = await post('/login/forgot_password/reset', { givenEmail, otp, newPassword, newPasswordConfirm });        
        const data = await response.json();

        if (data.passwordreset === true) {
            setLoading(false);
            success(response.status);

            if(location.state.isLogged) {
                
                const response = await post('/login/logout/signout', { userID });
                const data = await response.json();

                sessionStorage.removeItem("authToken");

                if (response.status === 'ok') {
                    success(response.status);
                    setLoading(false);
                    navigate("/login");
                } else {
                    error(data.error);
                } 
            } else {
                setLoading(false);
                navigate("/login");
            }
            
        } else {
            setLoading(false);
            error(data.error);
        }
    }

    return(
        <div className="login-wrapper">
            <h1>Réinitialisation du mot de passe</h1>
            <form autoComplete='off'>
                <label>
                    <p>Code</p>
                    <input type="text" id='otp' onChange={handleOTPInput}/>
                </label>
                <label>
                    <p>Nouveau mot de passe</p>
                    <input type="password" id='password' onChange={handlePwdInput}/>
                </label>
                <label>
                    <p>Confirmation du mot de passe</p>
                    <input type="password" id='password' onChange={handleConfirmInput}/>
                </label>
                <div>
                    {loading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                        <button onClick={submitReset}>Réinitialiser le mot de passe</button>
                    )}
                </div>
            </form>
        </div>
    )
};