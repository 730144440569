import React, { useEffect, useState } from "react";

export default function NotifNeutral({ successCode }) {
    const [notifText, setNotifText] = useState("");

    useEffect(() => {
        const findNotifText = () => {
            switch (successCode) {
                case 201:
                    setNotifText("Identifiants corrects.");
                    break;
                case 202:
                    setNotifText("Informations du partenaire mises à jour.");
                    break;
                case 203:
                    setNotifText("Image mise à jour.");
                    break;
                case 204:
                    setNotifText("Partenaire ajouté à la base de données.");
                    break;
                case 205:
                    setNotifText("Client ajouté à la base de données.");
                    break;
                case 206:
                    setNotifText("Partenaire supprimé.");
                    break;
                case 207:
                    setNotifText("Informations de l'opération mise à jour.");
                    break;
                case 208:
                    setNotifText("Contact supprimé.");
                    break;
                case 209:
                    setNotifText("Authentification réussie.");
                    break;
                case 210:
                    setNotifText("Informations du client mises à jour.");
                    break;
                case 212:
                    setNotifText("Feuille de partenaires importée avec succès.");
                    break;
                case 214:
                    setNotifText("Action créée avec succès.");
                    break;
                case 215:
                    setNotifText("Opération créée avec succès.");
                    break;
                case 217:
                    setNotifText("Partenaire supprimé de l'opération.");
                    break;
                case 218:
                    setNotifText("Nouvelle opération liée au partenaire.");
                    break;
                case 219:
                    setNotifText("Vous avez été déconnecté.");
                    break;
                case 220:
                    setNotifText("Action mise à jour.");
                    break;
                case 221:
                    setNotifText("Nouvel utilisateur créé.");
                    break;
                case 222:
                    setNotifText("Utilisateur supprimé.");
                    break;
                case 223:
                    setNotifText("Mot de passe mis à jour.");
                    break;
                default:
                    setNotifText("Opération réussie.");
                    break;
            }
        }

        findNotifText();

    });



    return (
        <div className="notif-wrapper">
            <div className="notifText">
                {notifText}
            </div>
            <div className="greenBar"></div>
        </div>
    );
}