import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Button from '@mui/material/Button';
import ActionCreate from "./ActionCreate";
import History from "./History";
import { Buffer } from 'buffer';
import Modal from '@mui/material/Modal';
import closing_arrow from "../images/closing_arrow_down.png";
import NotifBad from "./utils/NotifBad";
import NotifGood from "./utils/NotifGood";
import ContactCreate from "./ContactCreate";
import ActionsDetails from "./ActionsDetails";
import OperationsDetails from "./OperationsDetails";

export default function Partner_page({ error, success }) {
    const [ partner, setPartner ] = useState(null);
    const [ partnerSave, setPartnerSave ] = useState(null);
    const [ search, setSearch ] = useState('');
    const [ searchAssign, setSearchAssign ] = useState('');
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isEditable, setIsEditable ] = useState(false);
    const [ isEditableNotes, setIsEditableNotes] = useState(false);
    const [ isEditableContact, setIsEditableContact ] = useState(null);
    const [ openAddContact, setOpenAddContact ] = useState(false);
    const [ confirmDeleteContact, setConfirmDeleteContact ] = useState(false);
    const [ showCreateAction, setShowCreateAction ] = useState(false);
    const [ actions, setActions ] = useState([]);
    const [ showHistory, setShowHistory ] = useState(false);
    const [ image, setImage ] = useState(null);
    const [ infoOpen, setInfoOpen ] = useState(false);
    const [ operations, setOperations ] = useState([]);
    const [ opsTimeline, setOpsTimeline ] = useState(0);
    const [ linkOp, setLinkOp ] = useState(false);
    const [ unlinkOp, setUnlinkOp ] = useState(false);
    const [ operationsToUnlink, setOperationsToUnlink ] = useState([]);
    const [ operationsToLink, setOperationsToLink ] = useState([]);
    const [ opToLink, setOpToLink ] = useState(null);
    const [ opToUnlink, setOpToUnlink ] = useState(null);
    const [ openActionsDetails, setOpenActionsDetails ] = useState(null);
    const [ showOpsDetails, setShowOpsDetails ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const location = useLocation();
    const currentUrl = location.pathname;

    useEffect(() => {
        const fetchPartnerDetails = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                await axios.get(`/api/getPartner/${id}`)
                    .then((response) => {
                        setPartner(response.data);
                        const imageBuffer = Buffer.from(response.data.Image.data.data)
                        const imageEncoded = imageBuffer.toString('base64');
                        const imageData = atob(imageEncoded);
                        setImage(imageData);
                        setIsLoaded(true);
                    })
            } catch (e) {
                error(e.error);
            }
        };

        const fetchFutureActions = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                const response = await axios.get(`/api/getFutureActionsFor/${id}`);
                response.data.forEach(action => {
                    const date = new Date(action.Date);
                    action.Date = date;
                });
                setActions(response.data);
            } catch (e) {
                error(e.error);
            }
        };

        const fetchCurrentOps = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                await axios.get(`/api/getCurrentOpsFor/${id}`)
                .then((response) => {
                    response.data.forEach(operation => {
                        const date = new Date(operation["Date de fin"]);
                        operation["Date de fin"] = date;
                    });
                    setOperations(response.data);
                })
                .catch((e) => {
                    setErrorMessage(e.error);
                })
            } catch (e) {
                if (!errorMessage)
                    setErrorMessage(e.error);
                error(errorMessage);
            }
        };

        const fetchFutureOps = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                await axios.get(`/api/getFutureOpsFor/${id}`)
                .then((response) => {
                    response.data.forEach(operation => {
                        const date = new Date(operation["Date de lancement"]);
                        operation["Date de lancement"] = date;
                    });
                    setOperations(response.data);
                })
                .catch((e) => {
                    setErrorMessage(e.error);
                })
            } catch (e) {
                if (!errorMessage)
                    setErrorMessage(e.error);
                error(errorMessage);
            }
        };

        const fetchPastOps = async () => {
            try {
                const url = currentUrl.split('/');
                const id = url[url.length - 1];
                await axios.get(`/api/getPastOpsFor/${id}`)
                .then((response) => {
                    setOperations(response.data);
                })
                .catch((e) => {
                    setErrorMessage(e.error);
                })
            } catch (e) {
                if (!errorMessage)
                    setErrorMessage(e.response.error);
                error(errorMessage);
            }
        };

        fetchFutureActions();
        if (!isEditable) {
            fetchPartnerDetails();
        }

        if (opsTimeline === 0) {
            fetchCurrentOps();
        } else if (opsTimeline === 1) {
            fetchFutureOps();
        } else if (opsTimeline === -1) {
            fetchPastOps();
        }

    }, [image, opsTimeline, isEditableContact, linkOp, unlinkOp, openActionsDetails]);

    const handleModify = () => { setPartnerSave(partner); setIsEditable(true) }
    const handleModifyContact = (e) => { setPartnerSave(partner); setIsEditableContact(e.target.id) }
    const handleModifyCancel = () => { setPartner(partnerSave); setIsEditable(false) }
    const handleModifyCancelContact = () => { setPartner(partnerSave); setIsEditableContact(null) }
    const handleInfoModify = () => { setIsEditableNotes(true) }
    const handleInfoCancel = () => { setIsEditableNotes(false) }
    const handleShowAddContact = () => { setOpenAddContact(true) }
    const handleActionCreateShow = () => { setShowCreateAction(true) }
    const handleActionCreateClose = () => { setShowCreateAction(false) }
    const handleShowHistory = () => { setOpenActionsDetails(null); setShowHistory(true) }
    const handleHideHistory = () => { setShowHistory(false) }
    const handleInfoOpen = () => { setInfoOpen(true) }
    const handleInfoClose = () => { if (isEditableNotes) { setIsEditableNotes(false) }; setInfoOpen(false) }
    const handleLinkOpClose = () => { setOpToLink(null); setLinkOp(false) }
    const handleUnlinkOpClose = () => { setOpToUnlink(null); setUnlinkOp(false) }
    const handleActionDetailsClose = () => { setOpenActionsDetails(null) }

    const handleLinkOpOpen = async () => {
        try {
            await axios.get(`/api/getAllAvailableOps/${partner._id}`)
            .then((response) => {
                setOperationsToLink(response.data);
                setLinkOp(true);
            })
            .catch((e) => {
                error(e.error)
            });
        } catch (e) {
            setErrorMessage(e.error);
            error(e.error);
        }
    };

    const handleUnlinkOpOpen = async () => {
        try {
            await axios.get(`/api/getAllLinkedOps/${partner._id}`)
            .then((response) => {
                setOperationsToUnlink(response.data);
                setUnlinkOp(true);
            })
            .catch((e) => {
                error(e.error)
            });
        } catch (e) {
            error(e.error);
        }
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPartner({ ...partner, [name]: value });
    };

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        const contactId = isEditableContact;
        const contactIndex = partner.Contacts.findIndex((contact) => contact._id === contactId);
        const newContacts = partner.Contacts;
        newContacts[contactIndex][name] = value;
        setPartner({ ...partner, Contacts: newContacts });
    };

    const handleModifySave = async () => {
        try {
            await axios.post('/api/updatePartner', partner)
            .then((response) => {
                setIsEditableContact(null);
                setIsEditable(false);
                setIsEditableNotes(false);
                success(response.status);
            })
            .catch((e) => {
                error(e.error);
            })
        } catch (e) {
            error(e.error);
        }
    };

    const handleDeleteContact = async (e) => {
        try {
            const contactId = e.target.id;
            const contactIndex = partner.Contacts.findIndex((contact) => contact._id === contactId);
            const newContacts = partner.Contacts;
            newContacts.splice(contactIndex, 1);
            setPartner({ ...partner, Contacts: newContacts });

            await axios.post('/api/updatePartner', partner)
            .then((response) => {
                success(208);
            })
            .catch((e) => {
                error(e.error);
            })                

        } catch (e) {
            error(e.error);
        }
    };    

    const handleLogoClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
    
        if (file) {
          const reader = new FileReader();
    
          reader.onloadend = async () => {
            setImage(reader.result);
            try {
                const formData = new FormData();
                formData.append('image', file);
                formData.append('id', partner._id);
                // Send the image to the server endpoint
                await axios.post('/api/uploadPartnerPhoto', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .catch((e) => {
                    error(e.error);
                });
      
            } catch (e) {
                error(e.error);
            }
          };
    
          reader.readAsDataURL(file);
        }
    };

    const handleLinkClick = (e) => {
        if (!opToLink) {
            e.target.style.border = "2px solid #035efc";
            setOpToLink(e.target.id);
        } else if (opToLink === e.target.id) {
            e.target.style.border = "1px solid #FFF";
            setOpToLink(null);
        } else {
            document.getElementById(opToLink).style.border = "1px solid #FFF";
            e.target.style.border = "2px solid #035efc";
            setOpToLink(e.target.id);
        }
    };

    const handleUnlinkClick = (e) => {
        if (!opToUnlink) {
            e.target.style.border = "2px solid #035efc";
            setOpToUnlink(e.target.id);
        } else if (opToUnlink === e.target.id) {
            e.target.style.border = "1px solid #FFF";
            setOpToUnlink(null);
        } else {
            document.getElementById(opToUnlink).style.border = "1px solid #FFF";
            e.target.style.border = "2px solid #035efc";
            setOpToUnlink(e.target.id);
        }
    };

    const handleLinkToOp = async () => {
        try {
            const url = currentUrl.split('/');
            const id = url[url.length - 1];
            await axios.post('/api/linkOpToPartner', {id: id, opId: opToLink, name: partner.Société})
            .then((response) => {
                setOpToLink(null);
                setLinkOp(false);
                success(response.status);
            })
            .catch((e) => {
                error(e.error);
            });
        } catch (e) {
            error(e.error);
        }
    };

    const handleUnlinkFromOp = async () => {
        try {
            const url = currentUrl.split('/');
            const id = url[url.length - 1];
            await axios.post('/api/unlinkOpFromPartner', {id: id, opId: opToUnlink})
            .then((response) => {
                setOpToUnlink(null);
                setUnlinkOp(false);
                success(response.status);
            })
            .catch((e) => {
                error(e.error);
            });
        } catch (e) {
            error(e.error);
        }
    };

    const handleSetPresent = () => {
        setOpsTimeline(0);

        document.getElementById('operations_future').style.backgroundColor = "#FFF";
        document.getElementById('operations_future').style.color = "#000";
        document.getElementById('operations_past').style.backgroundColor = "#FFF";
        document.getElementById('operations_past').style.color = "#000";

        document.getElementById('operations_present').style.backgroundColor = "#666666";
        document.getElementById('operations_present').style.setProperty('color', '#FFF', 'important');
    };

    const handleSetFuture = () => {
        setOpsTimeline(1);

        document.getElementById('operations_present').style.backgroundColor = "#FFF";
        document.getElementById('operations_present').style.setProperty('color', '#666', 'important');
        document.getElementById('operations_past').style.backgroundColor = "#FFF";
        document.getElementById('operations_past').style.color = "#000";

        document.getElementById('operations_future').style.backgroundColor = "#666666";
        document.getElementById('operations_future').style.setProperty('color', '#FFF', 'important');
    };

    const handleSetPast = () => {
        setOpsTimeline(-1);

        document.getElementById('operations_future').style.backgroundColor = "#FFF";
        document.getElementById('operations_future').style.color = "#000";
        document.getElementById('operations_present').style.backgroundColor = "#FFF";
        document.getElementById('operations_present').style.setProperty('color', '#666', 'important');

        document.getElementById('operations_past').style.backgroundColor = "#666666";
        document.getElementById('operations_past').style.setProperty('color', '#FFF', 'important');
    };

    async function handleAddContact(contact) {
        try {
            const newContacts = partner.Contacts;
            newContacts.push(contact);
            setPartner({ ...partner, Contacts: newContacts });
            
            await axios.post('/api/updatePartner', partner)
            .then((response) => {
                setOpenAddContact(false);
                success(response.status);
            })
            .catch((e) => {
                error(e.error);
            })                

        } catch (e) {
            error(e.error);
        }
    };

    function openAction(actionId) {
        setShowHistory(false);
        setOpenActionsDetails(actionId);
    };

    return (
        <>
        {isLoaded ? (
        <div className="partner_page">
            {isLoaded && <div className="details_title">
                <h1 id="details_title_name">{partner.Société}</h1>
                <hr/>
                <h3 className="undertitle">{partner["Descriptif du partenaire"]}</h3>
            </div> }
            <div className="details_info">
                <div className="details_info_subtitle">
                    <h2 className="details_contact_subtitle">Informations</h2>
                    <hr/>
                    <h3 onClick={handleModify} className="undertitle">Modifier</h3>
                </div>
                {isLoaded && <div className="wrapper" id="details_info_content">
                    <div className="details_info_content_top">
                        <div className="details_info_content_left">
                            <label>
                                Adresse :
                                <input 
                                    type="text" 
                                    name="Adresse"
                                    value={partner.Adresse}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Code postal :
                                <input 
                                    type="text" 
                                    name="Code postal"
                                    value={partner["Code postal"]}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Ville :
                                <input 
                                    type="text" 
                                    name="Ville"
                                    value={partner.Ville}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Région :
                                <input 
                                    type="text" 
                                    name="Région"
                                    value={partner.Région}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Pays :
                                <input 
                                    type="text" 
                                    name="Pays"
                                    value={partner.Pays}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                        </div>
                        <div className="details_info_content_right">
                        <label>
                                Tel. fixe :
                                <input 
                                    type="text" 
                                    name="Téléphone fixe"
                                    value={partner["Téléphone fixe"]}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Email accueil :
                                <input 
                                    type="text" 
                                    name="Email_accueil"
                                    value={partner["Email_accueil"]}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            <label>
                                Secteur d'activité 1 :
                                <input 
                                    type="text" 
                                    name="Secteur d'activité 1"
                                    value={partner["Secteur d'activité 1"]}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label>
                            { partner["Secteur d'activité 2"] && <label>
                            Secteur d'activité 2 :
                                <input 
                                    type="text" 
                                    name="Secteur d'activité 2"
                                    value={partner["Secteur d'activité 2"]}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label> }
                            { partner["Secteur d'activité 3"] && <label>
                            Secteur d'activité 3 :
                                <input 
                                    type="text" 
                                    name="Secteur d'activité 3"
                                    value={partner["Secteur d'activité 3"]}
                                    onChange={handleInputChange}
                                    disabled={!isEditable}
                                    className="input-modif"
                                />
                            </label> }
                            <div id="details_info_buttons">
                                { isEditable ?
                                <label>
                                    Site web :
                                    <input 
                                        type="text" 
                                        name="Site web"
                                        value={partner["Site web"]}
                                        onChange={handleInputChange}
                                        disabled={!isEditable}
                                        className="input-modif"
                                    />
                                </label>
                                : <a href={partner["Site web"]} className="partner-website-link">Site web</a> }
                                {!isEditable && <label id="more_partner_info" onClick={handleInfoOpen}>Plus d'infos</label>}
                            </div>
                            <Modal
                                open={infoOpen}
                                onClose={handleInfoClose}
                            >
                                <div className="wrapper" id="partner_info_modal">
                                    <img src={closing_arrow} onClick={handleInfoClose} id="closing_arrow_info_modal"/>
                                    <div className="partner_info_modal_content">
                                        <div className="partner_info_modal_titles">
                                            <div className="partner_info_modal_title">
                                                <h1>Notes sur le partenaire</h1>
                                                <hr/>
                                            </div>
                                            <h3 className="undertitle" onClick={handleInfoModify}>Modifier</h3>
                                        </div>
                                        <div className="partner_info_modal_text">
                                            <textarea 
                                            rows="15"
                                            type="text" 
                                            name="Notes sur la société"
                                            value={partner["Notes sur la société"]}
                                            onChange={handleInputChange}
                                            disabled={!isEditableNotes}
                                            className="input-modif"
                                            id="partner_info_modal_textarea"
                                            />
                                        </div>
                                        <div className="partner_info_modal_buttons">
                                            { isEditableNotes && <Button className="cancel" onClick={handleInfoCancel}>Annuler</Button> }
                                            { isEditableNotes && <Button className="save" id="save_partner_info" onClick={handleModifySave}>Enregistrer</Button> }
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        { isLoaded && <div className="wrapper" id="details_info_content_logo" onClick={handleLogoClick}>
                            {image ? (
                                <img src={`data:image/png;base64,${image}`} alt="Uploaded" className="uploaded-image" />
                            ) : (
                                <p>Click to upload an image</p>
                            )}
                            <input
                                type="file"
                                id="fileInput"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </div> }
                    </div>
                    <div className="details_info_content_bottom">
                        {isEditable && <Button className="cancel" id="cancel-modif-partner" onClick={handleModifyCancel}>Annuler</Button>}
                        {isEditable && <Button className="save" id="save-modif-partner" onClick={handleModifySave}>Enregistrer</Button>}                        
                    </div>
                </div> }
            </div>
            <div className="details_contact">
                <div>
                    <h2 className="details_contact_subtitle">Contacts</h2>
                    <hr/>
                    <h3 onClick={handleShowAddContact} className="undertitle" id="add_contact_button">Ajouter un contact</h3>
                </div>
                <div className="details_contact_content">
                    { isLoaded && <ul className="contacts_list">
                        {partner.Contacts.map((contact) => (
                            <div className="wrapper contact_card" id={contact._id}>
                                <div className="contact_card_left">
                                    <label>
                                        Civilité :
                                        <input 
                                            type="text" 
                                            name="Civilité"
                                            value={contact["Civilité"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Nom :
                                        <input 
                                            type="text" 
                                            name="Nom du responsable"
                                            value={contact["Nom du responsable"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Prénom :
                                        <input 
                                            type="text" 
                                            name="Prénom du responsable"
                                            value={contact["Prénom du responsable"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Tel. fixe :
                                        <input 
                                            type="text" 
                                            name="Téléphone fixe"
                                            value={contact["Téléphone fixe"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Portable :
                                        <input 
                                            type="text" 
                                            name="Portable"
                                            value={contact["Portable"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <label>
                                        Email :
                                        <input 
                                            type="text" 
                                            name="Email"
                                            value={contact["Email"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                </div>
                                <div className="contact_card_right">
                                    <label>
                                        Fonction :
                                        <input 
                                            type="text" 
                                            name="Fonction"
                                            value={contact["Fonction"]}
                                            onChange={handleContactChange}
                                            disabled={isEditableContact !== contact._id}
                                            className="input-modif"
                                        />
                                    </label>
                                    <div className="contact_card_buttons">
                                        { isEditableContact !== contact._id ? <Button className="save save_contact" id={contact._id} onClick={handleModifyContact}>Modifier</Button> : <Button className="cancel" onClick={handleModifyCancelContact}>Annuler</Button> }
                                        { isEditableContact !== contact._id ? <Button className="cancel delete_contact" onClick={() => setConfirmDeleteContact(true)}>Supprimer</Button> : <Button className="save delete_contact" onClick={handleModifySave}>Enregistrer</Button> }
                                    </div>
                                    <Modal open={confirmDeleteContact}>
                                        <div className="wrapper" id="confirm_delete_contact_modal">
                                            <h2>Voulez-vous vraiment supprimer ce contact ?</h2>
                                            <div className="confirm-delete-buttons">
                                                <Button className="wrapper confirm-delete-contact-button" onClick={() => setConfirmDeleteContact(false)}>Annuler</Button>
                                                <Button className="cancel" onClick={handleDeleteContact} id={contact._id}>Supprimer</Button>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                        ))}
                    </ul>}
                </div>
            </div>
            <div className="details_actions">
                <div className="details_actions_subtitle">
                    <h2 className="details_contact_subtitle">Actions</h2>
                    <hr/>
                    <div className="details_actions_subtitle_buttons">
                        <h3 className="undertitle" onClick={handleActionCreateShow}>Créer une action</h3>
                        <h3 className="undertitle" onClick={handleShowHistory}>Historique</h3>
                    </div>
                </div>
                <div className="details_actions_content">
                    { isLoaded && <ul className="actions_list">
                        {actions.map((action) => (
                            <div className="wrapper" id="action_card" onClick={() => { setShowHistory(false); setOpenActionsDetails(action._id) } } key={action._id}>
                                <h2 id="action_card_title">{action.Nom}</h2>
                                <div className="action_card_infos">
                                    <div className="action_card_left">
                                        <div id="action_date">
                                            Date : {action.Date.getDate()}/{action.Date.getMonth() + 1}/{action.Date.getFullYear()}
                                        </div>
                                        <div id="action_hour">
                                            Heure : {action.Date.getHours()}h{action.Date.getMinutes() === 0 ? '00' : action.Date.getMinutes()}
                                        </div>
                                    </div>
                                    <div className="action_card_right">
                                        <div id="action_contact">
                                            Contact : {action.Contact}
                                        </div>
                                        <div id="action_operations">
                                            {action.Opérations.map((operation) => (
                                                <div className="action_operation">
                                                    Opération : {operation.nom}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ul>}
                    { openActionsDetails && <ActionsDetails id={openActionsDetails} onClose={handleActionDetailsClose} partnerSide={true} error={error} success={success} /> }
                </div>
            </div>
            <div className="details_operations">
                <div className="details_operations_subtitle">
                    <h2 className="details_contact_subtitle">Opérations</h2>
                    <hr/>
                    <div className="details_actions_subtitle_buttons">
                        <h3 className="undertitle" onClick={handleLinkOpOpen}>Lier une opération</h3>
                        <h3 className="undertitle" onClick={handleUnlinkOpOpen}>Enlever une opération</h3>                            
                    </div>
                    <Modal open={linkOp} onClose={handleLinkOpClose}>
                        <div className="wrapper" id="link_op_modal">
                            <div className="link_op_modal_content">
                                <div className="link_op_modal_titles">
                                    <div className="link_op_modal_title">
                                        <h1>Opérations</h1>
                                        <hr id="hr_op_assign"/>
                                    </div>
                                </div>
                                <input className='searchbar_base' id="searchbar_operations_assign" type='text' placeholder='Rechercher' onChange={(e) => setSearchAssign(e.target.value)} value={searchAssign}/>
                                <div className="link_op_modal_text">
                                    <ul className="operations_list">
                                        {operationsToLink.filter((operation) => {
                                            if (searchAssign === "") {
                                                return operation;
                                            } else if (operation.Nom.toLowerCase().includes(searchAssign.toLowerCase())) {
                                                return operation;
                                            }
                                        }).map((operation) => (
                                            <div className="wrapper operation_card operationLink_card" id={operation._id} onClick={handleLinkClick} key={operation._id}>
                                                <h2 className="operation_card_title operationLink_card_title" onClick={(e) => { e.stopPropagation() }}>{operation.Nom}</h2>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                                <div className="link_op_modal_buttons">
                                    <Button className="cancel" onClick={handleLinkOpClose}>Annuler</Button>
                                    <Button className="save" id="save_link_op" onClick={handleLinkToOp}>Valider</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal open={unlinkOp} onClose={handleUnlinkOpClose}>
                        <div className="wrapper" id="link_op_modal">
                            <div className="link_op_modal_content">
                                <div className="link_op_modal_titles">
                                    <div className="link_op_modal_title">
                                        <h1>Opérations</h1>
                                        <hr id="hr_op_assign"/>
                                    </div>
                                </div>
                                <input className='searchbar_base' id="searchbar_operations_assign" type='text' placeholder='Rechercher' onChange={(e) => setSearchAssign(e.target.value)} value={searchAssign}/>
                                <div className="link_op_modal_text">
                                    <ul className="operations_list">
                                        {operationsToUnlink.filter((operation) => {
                                            if (searchAssign === "") {
                                                return operation;
                                            } else if (operation.Nom.toLowerCase().includes(searchAssign.toLowerCase())) {
                                                return operation;
                                            }
                                        }).map((operation) => (
                                            <div className="wrapper operation_card operationLink_card" id={operation._id} onClick={handleUnlinkClick} key={operation._id}>
                                                <h2 className="operation_card_title operationLink_card_title" onClick={(e) => { e.stopPropagation() }}>{operation.Nom}</h2>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                                <div className="link_op_modal_buttons">
                                    <Button className="cancel" onClick={handleUnlinkOpClose}>Annuler</Button>
                                    <Button className="save" id="save_link_op" onClick={handleUnlinkFromOp}>Valider</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className="details_operations_buttons">
                    <div className="details_operations_buttons_left">
                        <Button className="operations_time_buttons" id='operations_present' onClick={handleSetPresent}>En cours</Button>
                        <Button className="operations_time_buttons" id='operations_future' onClick={handleSetFuture}>Futures</Button>
                        <Button className="operations_time_buttons" id='operations_past' onClick={handleSetPast}>Passées</Button>
                    </div>
                    <input className='searchbar_base' id="searchbar_operations" type='text' placeholder='Recherche rapide' onChange={(e) => setSearch(e.target.value)} value={search}/>
                </div>
                <div className="wrapper" id="details_operations_content">
                    <ul className="operations_list">
                        {operations.filter((operation) => {
                            if (search === "") {
                                return operation;
                            } else if (operation.Nom.toLowerCase().includes(search.toLowerCase())) {
                                return operation;
                            }
                        }).map((operation) => (
                            <div className="wrapper operation_card" onClick={() => setShowOpsDetails(operation._id)}>
                                <h2 className="operation_card_title">{operation.Nom}</h2>
                                <h4 id="operation_card_date">{operation["Date de fin"] ? `Date de fin : ${operation["Date de fin"].getDate()}/${operation["Date de fin"].getMonth() + 1}` : operation["Date de lancement"] ? `Date de lancement : ${operation["Date de lancement"].getDate()}/${operation["Date de lancement"].getMonth() + 1}` : ""}</h4>
                            </div>
                        ))}
                    </ul>
                </div>
            </div>
            { openAddContact && <ContactCreate onClose={() => setOpenAddContact(false)} onValidate={handleAddContact} error={error} success={success} /> }
            { showCreateAction && <ActionCreate
                                    onClose={handleActionCreateClose}
                                    onValidate={handleActionCreateClose}
                                    partnerId={partner._id}
                                    partnerSide={true}
                                    error={error}
                                    success={success}
                                    /> }
            { showHistory && <History onClose={handleHideHistory} partnerId={partner._id} openAction={openAction} error={error} success={success} />}
                </div> ) : (<h1 id="loading-partner">Chargement...</h1>) }
            { showOpsDetails && <OperationsDetails partnerSide={true} id={showOpsDetails} partnerId={partner._id} onClose={() => setShowOpsDetails(null)} error={error} success={success}/>}
        </>
    );
};