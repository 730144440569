import React from "react";

export default function NotifNeutral({ errorMessage }) {

    return (
        <div className="notif-wrapper">
            <div className="notifText">
                {errorMessage}
            </div>
            <div className="redBar"></div>
        </div>
    );
}