import React, { useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import axios from "axios";

export default function OperationCreate({ onClose, partnerId, error, success }) {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateEnd, setSelectedDateEnd] = useState(null);
    const [title, setTitle] = useState();
    const [contact, setContact] = useState();
    const [subject, setSubject] = useState();

    const handleTitleChange = (e) => { setTitle(e.target.value) };
    const handleContactChange = (e) => { setContact(e.target.value) };
    const handleSubjectChange = (e) => { setSubject(e.target.value) };

    const handleValidate = async () => {
        const operation = {
            "Nom": title,
            "Date de lancement": selectedDate,
            "Date de fin": selectedDateEnd,
            "Client": partnerId,
            "Notes": subject,
            "Ressources": [],
            "Budget": contact,
            "Partenaires": []
        };
        await axios.post('/api/addOperation', operation)
        .then(response => {
            success(215);
            onClose();
        })
        .catch(err => error(err.error));
    }

    return (
        <div className="wrapper create-op-wrapper">
            <div id="create_action_title_wrapper">
                <h1 id="create_action_title">Créer une opération</h1>
                <hr/>
            </div>
            <div id="create_action_form_wrapper">
                <div id="labels">
                    <label htmlFor="title">Titre :</label>
                    <label htmlFor="contact" placeholder="€">Budget :</label>
                    <label htmlFor="subject" id="operationlabelsubject">Description :</label>
                    <label htmlFor="operation" id="opcreationdatelabel1">Date de début :</label>
                    <label htmlFor="operation">Date de fin :</label>
                </div>
                <div id="inputs">
                    <form className="form" id="create_action_givetitle">
                        <input type="text" className="input" id="actionformtitle" name="title" onChange={handleTitleChange} required/>
                    </form>
                    <form className="form" id="create_action_givecontact">
                        <input type="text" className="input" id="actionformcontact" name="contact" onChange={handleContactChange} required/>
                    </form>
                    <form className="form" id="create_action_givesubject">
                        <textarea rows={6} type="text" className="input" id="actionformsubject" name="subject" onChange={handleSubjectChange} required/>
                    </form>
                    <div className="operationformdatechoice">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker value={selectedDate} onChange={(date) => setSelectedDate(date)} />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker value={selectedDateEnd} onChange={(date) => setSelectedDateEnd(date)} />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
            <div id="create_operation_buttons">
                <Button className="cancel" id="cancel_create_action" onClick={onClose}>Annuler</Button>
                <Button className="save" id="save_create_action" onClick={handleValidate}>Enregistrer</Button>
            </div>
        </div>
    )
}