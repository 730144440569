import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';

import useAuth from '../utils/AuthContext';
import { post } from '../utils/backRequests';

import bogo from '../images/bogogroup.png';
import quadrabar from '../images/Bande_color_2.png'

export default function Login({ error, success }) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const { checkAuthStatus } = useAuth();

    const navigate = useNavigate();

    async function submitLogin(event) {
        event.preventDefault();
        setLoading(true);

        const response = await post('/login/user/signin', { email, password });        
        const data = await response.json();

        if (data.user && data.verified === true) {
            setEmail("");
            setPassword("");

            // Auhtprovider update
            await checkAuthStatus();
            setLoading(false);
            // success(201);
            navigate('/home/recap');

        } else if (data.user && data.verified === false) {
            await post('login/email_verification', { email });
            const data2 = { email: email };
            setEmail("");
            setPassword("");
            setLoading(false);
            navigate('/email-verification', { state: data2 });

        } else {
            error(data.error);
        }

    }

    const handleEmailInput = (e) => setEmail(e.target.value);
    const handlePwdInput = (e) => setPassword(e.target.value);

    return(
    <div className='connection-page'>
        <img src={quadrabar} id='quadra'/>
        <div className='connection-content'>
            <img src={bogo} id="bogologo_connec" />
            <div className="login-wrapper">
                <h1 id='connection-title'>Connexion</h1>
                <form autoComplete='on' id='login-form'>
                    <label>
                        <p id='input-title'>Email</p>
                        <input type="text" id='email' onChange={handleEmailInput}/>
                    </label>
                    <label id='pass-form'>
                        <p id='input-title'>Mot de passe</p>
                        <input type="password" id='password' onChange={handlePwdInput}/>
                    </label>
                    <NavLink to="forgot-password" id='pwforgot'>Mot de passe oublié ?</NavLink>
                    <div>
                        {loading ? (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : (
                            <button onClick={submitLogin} id='connexion'>Se connecter</button>
                            )}
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
};