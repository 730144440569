import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Select from 'react-dropdown-select';
import axios from 'axios';
import NotifBad from './utils/NotifBad';

export default function Filtres({ onClose, onValidate, partnerSide, error, success }) {

    const [type, setType] = useState();
    const [domaine, setDomaine] = useState([]);
    const [domainOptions, setDomainOptions] = useState([]);
    const [operation, setOperation] = useState([]);
    const [operationsOptions, setOperationsOptions] = useState([]);
    const [responsable, setResponsable] = useState([]);
    const [responsableOptions, setResponsableOptions] = useState([]);
    const [reunions, setReunions] = useState();

    useEffect(() => {
        if (partnerSide) {
            axios.get('http://localhost:9000/api/getDomaines')
            .then(response => {
                const domaines = response.data.map(dom => {
                    return { id: dom, name: dom };
                });
                setDomainOptions(domaines);
            })
            .catch(err => {
                error(err.error);
            });
        }

        axios.get('http://localhost:9000/api/getOperations')
        .then(response => {
            const operation = response.data.map(op => {
                return { id: op._id, name: op.Nom };
            });
            setOperationsOptions(operation);
        })
        .catch(err => {
            error(err.error);
        });

        if (partnerSide) {
            axios.get('http://localhost:9000/api/getResponsables')
            .then(response => {
                const responsables = response.data.map(resp => {
                    return { id: resp, name: resp };
                });
                setResponsableOptions(responsables);
            })
            .catch(err =>{
                error(err.error);
            });
        } else {
            axios.get('http://localhost:9000/api/getResponsablesClients')
            .then(response => {
                const responsables = response.data.map(resp => {
                    return { id: resp, name: resp };
                });
                setResponsableOptions(responsables);
            })
            .catch(err => {
                error(err.error);
            });
        }

    }, []);
    
    const handleEraseType = () => setType([]);
    const handleEraseDomaine = () => setDomaine([]);
    const handleEraseOperations = () => setOperation([]);
    const handleEraseResponsable = () => setResponsable([]);
    const handleEraseReunions = () => setReunions([]);
    const handleTypeChange = (e) => setType(e.target.value);

    const handleEraseFilters = () => {
        setType();
        setDomaine([]);
        setOperation([]);
        setResponsable([]);
        setReunions();
    }

    const handleValidate = async () => {
        const finalData = [];
        if (type === undefined) {
            finalData.push([]);
        } else {
            const typeArray = [type];
            finalData.push(typeArray);
        }
        const domaines = domaine.map(dom => dom.id);
        finalData.push(domaines);
        const operations = operation.map(op => op.id);
        finalData.push(operations);
        const responsables = responsable.map(resp => resp.id);
        finalData.push(responsables);
        if (reunions === undefined) {
            finalData.push([]);
        } else {
            const reunionsArray = [reunions];
            finalData.push(reunionsArray);
        }

        onValidate(finalData);
    }
   
    return (
    <div className="filters">
        <div id='filtres-title-wrapper'>
            <h1 id='filtres-title'>Filtres</h1>
            <hr id='filtres-title-hr'/>
        </div>
        <div id='filters-wrapper'>
            <div id='type'>
                <h2 className='filtres-undertitle'>Type</h2>
                <hr className='filtres-hr'/>
                <div className='filtres_options'>
                    <h3 className='filtres_select_all'>Tout sélectionner</h3>
                    <h3 className='filtres_erase' onClick={handleEraseType}>Effacer</h3>
                </div>
                <select className="searchbar_filtres" id='typeselect' onChange={handleTypeChange}>
                    <option id='drop-option' value=""></option>
                    <option id='drop-option' value="partner">Partenaire</option>
                    <option id='drop-option' value="prospect">Prospect</option>
                </select>
            </div>
            <div id='domaine'>
                <h2 className='filtres-undertitle'>Domaine</h2>
                <hr className='filtres-hr'/>
                <div className='filtres_options'>
                    <h3 className='filtres_select_all'>Tout sélectionner</h3>
                    <h3 className='filtres_erase' onClick={handleEraseDomaine}>Effacer</h3>
                </div>
                <Select
                    name='select'
                    className='searchbar_filtres'
                    options={domainOptions}
                    labelField="name"
                    valueField="id"
                    multi
                    onChange={(value) => setDomaine(value)}
                />
            </div>
            <div id='operations'>
                <h2 className='filtres-undertitle'>Opérations</h2>
                <hr className='filtres-hr'/>
                <div className='filtres_options'>
                    <h3 className='filtres_select_all'>Tout sélectionner</h3>
                    <h3 className='filtres_erase' onClick={handleEraseOperations}>Effacer</h3>
                </div>
                <Select
                    name='select'
                    className='searchbar_filtres'
                    options={operationsOptions}
                    labelField="name"
                    valueField="id"
                    multi
                    onChange={(value) => setOperation(value)}
                />
            </div>
            <div id='responsable'>
                <h2 className='filtres-undertitle'>Responsable</h2>
                <hr className='filtres-hr'/>
                <div className='filtres_options'>
                    <h3 className='filtres_select_all'>Tout sélectionner</h3>
                    <h3 className='filtres_erase' onClick={handleEraseResponsable}>Effacer</h3>
                </div>
                <Select
                    name='select'
                    className='searchbar_filtres'
                    options={responsableOptions}
                    labelField="name"
                    valueField="id"
                    multi
                    onChange={(value) => setResponsable(value)}
                />
            </div>
            <div id='reunions'>
                <h2 className='filtres-undertitle'>Réunions</h2>
                <hr className='filtres-hr'/>
                <div className='filtres_options'>
                    <h3 className='filtres_select_all'>Tout sélectionner</h3>
                    <h3 className='filtres_erase' onClick={handleEraseReunions}>Effacer</h3>
                </div>
                <select className="searchbar_filtres" id='typeselect' onChange={handleTypeChange}>
                    <option id='drop-option' value=""></option>
                    <option id='drop-option' value="10">10 prochaines</option>
                    <option id='drop-option' value="5">5 prochaines</option>
                    <option id='drop-option' value="-5">5 dernières</option>
                    <option id='drop-option' value="-10">10 dernières</option>
                </select>
            </div>
        </div>
        <div id='filtres_buttons'>
            <Button id='erase-all-filters' onClick={handleEraseFilters}>Effacer tous les filtres</Button>
            <div id='filtres_buttons_colored'>
                <Button id='filtres-cancel' onClick={onClose}>Annuler</Button>
                <Button id='filtres-validate' onClick={handleValidate}>Valider</Button>
            </div>
        </div>
    </div>
  );
}
