import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import Home from './components/Home';
import Login from './components/Login';
import Verif from './components/Verif';
import Reset from './components/Reset';
import Forgot_Password from './components/Forgot_password.js';
import PrivateRoutes from './utils/PrivateRoutes';
import { AuthProvider } from './utils/AuthContext';
import NotifBad from './components/utils/NotifBad';
import NotifGood from './components/utils/NotifGood';


function App() {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successCode, setSuccessCode] = useState('');

  const user = sessionStorage.getItem('authToken');

  const openError = ((errorMessage) => {
    setErrorMessage(errorMessage);
    setShowError(true);
    setTimeout(() => {
        setShowError(false);
        setErrorMessage(null);
    }, 5000);
});

  const openSuccess = ((successCode) => {
    setSuccessCode(successCode);
    setShowSuccess(true);
    setTimeout(() => {
        setShowSuccess(false);
    }, 3000);
  });

  return (
    <AuthProvider user={user} error={openError}>
      <BrowserRouter>
        <main>
            <Routes>
              <Route path='/login' element={<Login error={openError} success={openSuccess}/>} />
              <Route path='/email-verification' element={<Verif error={openError} success={openSuccess}/>}/>
              <Route path='/login/forgot-password' element={<Forgot_Password error={openError} success={openSuccess}/>}/>
              <Route path='/reset' element={<Reset error={openError} success={openSuccess}/>} />
              <Route element={<PrivateRoutes isAllowed={4}/>}>
                <Route path='/home/*' element={<Home error={openError} success={openSuccess}/>} />
              </Route>
            </Routes>
            { showError && <NotifBad errorMessage={errorMessage} /> }
            { showSuccess && <NotifGood successCode={successCode} /> }
        </main>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
