import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Checkbox from './utils/Checkbox';
import Filtres from './Filtres';
import { NavLink } from 'react-router-dom';
const { TextEncoder } = require('util');


export default function Base_partners({ error, success }) {

    const [ createOpen, setCreateOpen ] = useState(false);
    const [ allPartners, setAllPartners ] = useState([]);
    const [ selection, setSelection ] = useState([]);
    const [ allSelected, setAllSelected ] = useState(false);
    const [ filters, setFilters ] = useState([]);
    const [ page, setPage ] = useState(1);
    const [ previous, setPrevious ] = useState([]);
    const [ next, setNext ] = useState([]);
    const [ current, setCurrent] = useState([]);
    const [ loading, setLoading] = useState(false);
    const [ showFilters, setShowFilters ] = useState(false);
    const [ nextPage, setNextPage ] = useState(false);
    const [ search, setSearch ] = useState('');
    const [ filtered, setFiltered ] = useState(false);
    const [ form, setForm ] = useState({
        "name": '',
        "adress": '',
        "postal": '',
        "city": '',
        "region": '',
        "country": '',
        "phone": '',
        "emailAccueil": '',
        "sector1": '',
        "sector2": '',
        "sector3": '',
        "site": '',
        "notes": '',
        "civil": '',
        "name2": '',
        "name3": '',
        "phone2": '',
        "phone3": '',
        "email": '',
        "functionn": '',
        "prospect": true,
        "description": ''
    });
    
    const fetchData = async () => {
        await axios.get(`/api/getPartnersForPage?searchQuery=${search}`)
        .then(response => {
            setAllPartners(response.data);
            if (response.data.length > 21) {
                const splitIndex = 21;
                const firstPart = response.data.splice(0, splitIndex);
                const secondPart = response.data.splice(0, splitIndex);
                setCurrent(firstPart);
                setNext(secondPart);
                setNextPage(true);
            } else {
                setCurrent(response.data);
            }
        })
        .catch(err => error(err.error));
    };

    useEffect(() => {    
        if (!filtered) {
            fetchData();
        }
    }, [search, createOpen]);
    
    const handleAddPartner = () => setCreateOpen(true);
    const handleCancel = () => setCreateOpen(false);
    const handleCreateClose = () => setCreateOpen(false);
    const handleFormInput = (e) => setForm({ ...form, name: e.target.value });
    const handleShowFilter = () => setShowFilters(true);
    const handleCloseFilters = () => setShowFilters(false);

    const handlePrevPage = async () => {
        setPage(parseInt(page) - 1);
        const transi = current;
        setCurrent(previous);
        setNext(transi);
        const save = allPartners;
        const newPrevious = save.slice((parseInt(page) - 2) * 21, (parseInt(page) - 1) * 21);
        setPrevious(newPrevious);
        setNextPage(true);

        if (allSelected) {
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.click();
            });
        }
    };

    const handleNextPage = async () => {
        setPage(parseInt(page) + 1);
        const transi = current;
        setCurrent(next);
        setPrevious(transi);
        const save = allPartners;
        const newNext = save.slice(parseInt(page) * 21, (parseInt(page) + 1) * 21);
        setNext(newNext);

        if (next.length < 21) {
            setNextPage(false);
        }

        if (allSelected) {
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                checkbox.click();
            });
        }

    };

    function handleImportPartner() {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.csv';

        input.onchange = async (event) => {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            await axios.post('/api/importPartners', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                success(response.status);
            })
            .catch(err => {
                error(err.error);
            });
        };

        input.click();
    };
    
    const handleAllSelection = async () => {
        // console.log(selection, allSelected)
        if (!allSelected) {
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                if (!checkbox.checked) { checkbox.click(); }
            });
        } else {
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            checkboxes.forEach((checkbox) => {
                if (checkbox.checked) { checkbox.click(); }
            });
        }

        if (filters.length === 0) {
            if (!allSelected) {
                setSelection([]);

                await axios.get('/api/getPartners')
                .then(response => {
                    const res = response.data;
                    // console.log(res);
                    setSelection((prevIds) => {
                        // Create a Set with the existing IDs
                        const existingIds = new Set(prevIds);

                        // Add only unique IDs from the response
                        res.forEach(element => {
                            existingIds.add(element._id);
                        });
            
                        // Convert the Set back to an array
                        return [...existingIds];
                    });
                })
                .catch(err => error(err.error));
            } else {
                setSelection([]);
            }
        } else {
            //TODO
            console.log('Not treated yet');    
        }
        await setAllSelected(!allSelected);
        
    };
    
    async function handleExportSelection() {
        try {
            const response = await axios.post('/api/exportPartners', {
                ids: selection
            }, {
            responseType: 'blob', // Important for handling binary data
            });

            // Create a blob from the response data
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });

            // Create a link element and trigger a download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'partners.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            error(e.error);
        }
    };
    
    const handleMailSelection = async () => {
        const mailing_list = [];
    
        // Use Promise.all to wait for all asynchronous requests to complete
        await Promise.all(
            selection.map(async (element) => {
                try {
                    const response = await axios.get(`/api/getPartner/${element}`);
                    mailing_list.push(response.data.Email_accueil);
                } catch (err) {
                    error(err.error);
                }
            })
        );
    
        const emailAddresses = mailing_list.join();
        const mailtoLink = 'mailto:' + encodeURIComponent(emailAddresses);
    
        window.location.href = mailtoLink;
    };
    
    async function submitCreatePartner(event) {
        event.preventDefault();
        setLoading(true);
        // console.log("Clicked Login");
        
        const newPartner = {
            "Société": form.name,
            "Adresse": form.adress,
            "Code postal": form.postal,
            "Ville": form.city,
            "Région": form.region,
            "Pays": form.country,
            "Téléphone fixe": form.phone,
            "Email_accueil": form.emailAccueil,
            "Secteur d'activité 1": form.sector1,
            "Secteur d'activité 2": form.sector2,
            "Secteur d'activité 3": form.sector3,
            "Site web": form.site,
            "Notes sur la société": form.notes,
            "Descriptif du partenaire" : form.description,
            "Image": { "data": ""},
            "Contacts": [
                {
                    "Civilité": form.civil,
                    "Nom du responsable": form.name2,
                    "Prénom du responsable": form.name3,
                    "Téléphone fixe": form.phone2,
                    "Portable": form.phone3,
                    "Email": form.email,
                    "Fonction": form.functionn
                }
            ],
            "Opérations": [],
            "roles": ["Partner"],
            "prospect": form.prospect
        }

        // const newPartner = JSON.stringify(newPartner1);

        await axios.post('/api/addPartner', { newPartner })
        .then(response => {
            setLoading(false);
            success(response.status);
            setCreateOpen(false);
        })
        .catch(err => {
            error(err.error);
            setLoading(false);
        });
    };

    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            // If the checkbox is checked, add the ID to the array
            setSelection((prevIds) => [...prevIds, id]);
        } else {
            // If the checkbox is unchecked, remove the ID from the array
            setSelection((prevIds) => prevIds.filter((prevId) => prevId !== id));
            setAllSelected(false);
        } 
    };

    const handleFiltersValidation = async (finalData) => {
        try {
            if (!finalData[0].length > 0 && !finalData[1].length > 0 && !finalData[2].length > 0 && !finalData[3].length > 0 && !finalData[4].length > 0) {
                setFiltered(false);
                setFilters([]);
                fetchData();
                setShowFilters(false);
            } else {
                const sendFilters = JSON.stringify(finalData);
                // console.log(sendFilters);
                await axios.post('/api/getFilteredPartners', { sendFilters })
                .then(response => {
                    const res = response.data;
                    // console.log(res);
                    setFiltered(true);
                    setShowFilters(false);
                    setAllPartners(res);
                    if (res.length > 21) {
                        const splitIndex = 21;
                        const firstPart = res.splice(0, splitIndex);
                        const secondPart = res.splice(0, splitIndex);
                        setCurrent(firstPart);
                        setNext(secondPart);
                        setNextPage(true);
                    } else {
                        setCurrent(res);
                        setNext([]);
                        setNextPage(false);
                    }
                })
                .catch(err => error(err.error));
            }
        } catch (e) {
            error(e.error);
            setShowFilters(false);
        }
    };

    return (
        <div className="base_partners">
            <div className="add_to_base">
                <h1 id='admin-title'>Ajouter</h1>
                <hr/>
                <div id='actions_add'>
                    <h3 className='undertitle' onClick={handleAddPartner}>Ajouter un partenaire</h3>
                        <Modal
                            open={createOpen}
                            onClose={handleCreateClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div className="create_from_admin_content">
                                <div className='wrapper create-partner-form-complete-wrapper'>
                                    <form autoComplete='off' id='create-partner-form'>
                                        <h1 id='user-create-modal-title'>Ajouter un partenaire</h1>
                                        <div className='form-entries'>
                                            <label>
                                                <p id='input-title'>Société</p>
                                                <input className="long-box" type="text" name='name' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Adresse</p>
                                                <input className="long-box" type="text" name='adress' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Code postal</p>
                                                <input className="long-box" type="text" name='postal' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Ville</p>
                                                <input className="long-box" type="text" name='city' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Région</p>
                                                <input className="long-box" type="text" name='region' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Pays</p>
                                                <input className="long-box" type="text" name='country' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Téléphone fixe</p>
                                                <input className="long-box" type="text" name='phone' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Email accueil</p>
                                                <input className="long-box" type="text" name='emailAccueil' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Secteur d'activité 1</p>
                                                <input className="long-box" type="text" name='sector1' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Secteur d'activité 2</p>
                                                <input className="long-box" type="text" name='sector2' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Secteur d'activité 3</p>
                                                <input className="long-box" type="text" name='sector3' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Site web</p>
                                                <input className="long-box" type="text" name='site' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Notes sur la société</p>
                                                <input className="long-box" type="text" name='notes' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <label>
                                                <p id='input-title'>Descriptif du partenaire</p>
                                                <input className="long-box" type="text" name='description' id='email' onChange={handleFormInput}/>
                                            </label>
                                            <div className='contacts_entry'>
                                                <h2 id='partner_create_contact_title'>Contact</h2>
                                                <label>
                                                    <p id='input-title'>Civilité</p>
                                                    <input className="long-box" type="text" name='civil' id='email' onChange={handleFormInput}/>
                                                </label>
                                                <label>
                                                    <p id='input-title'>Nom</p>
                                                    <input className="long-box" type="text" name='name2' id='email' onChange={handleFormInput}/>
                                                </label>
                                                <label>
                                                    <p id='input-title'>Prénom</p>
                                                    <input className="long-box" type="text" name='name3' id='email' onChange={handleFormInput}/>
                                                </label>
                                                <label>
                                                    <p id='input-title'>Téléphone fixe</p>
                                                    <input className="long-box" type="text" name='phone2' id='email' onChange={handleFormInput}/>
                                                </label>
                                                <label>
                                                    <p id='input-title'>Portable</p>
                                                    <input className="long-box" type="text" name='phone3' id='email' onChange={handleFormInput}/>
                                                </label>
                                                <label>
                                                    <p id='input-title'>Email</p>
                                                    <input className="long-box" type="text" name='email' id='email' onChange={handleFormInput}/>
                                                </label>
                                                <label>
                                                    <p id='input-title'>Fonction</p>
                                                    <input className="long-box last-long-box" type="text" name='functionn' id='email' onChange={handleFormInput}/>
                                                </label>
                                            </div>
                                            <label className='create-partner-dropdown' id='input-title-dropdown' htmlFor="userRole">Prospect</label>
                                            <select className='create-partner-dropdown' id="userRole" value={form.prospect} name='prospect' onChange={handleFormInput}>
                                                <option id='drop-option' value={true}>Oui</option>
                                                <option id='drop-option' value={false}>Non</option>
                                            </select>
                                        </div>
                                        <div id='create_buttons'>
                                        <Button onClick={handleCancel} className="cancel cancel-create-partner">Annuler</Button>
                                        {loading ? (
                                            <button id='create-user-button'>Chargement...</button>
                                        ) : (
                                            <Button onClick={submitCreatePartner} className="create-user-button" id='connexion'>Valider</Button>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    <h3 className='undertitle' onClick={handleImportPartner}>Importer un tableau</h3>
                </div>
            </div>
            <div className="search_base">
                <h1 id='admin-title'>Rechercher</h1>
                <hr/>
                <div id='actions_add'>
                    <div className='actions_wrapper'>
                        <h3 className='undertitle' onClick={handleAllSelection}>Tout sélectionner</h3>
                        <h3 className='undertitle' onClick={handleShowFilter}>Filtres</h3>
                        <h3 className='undertitle' onClick={handleExportSelection}>Exporter la sélection</h3>
                        <h3 className='undertitle' onClick={handleMailSelection}>Envoyer un mail à la sélection</h3>
                    </div>
                    <input className='searchbar_base' type='text' placeholder='Rechercher un partenaire' onChange={(e) => setSearch(e.target.value)} value={search}/>
                </div>
                <div id='base_cards'>
                    <div className='base_card_row'>
                        <ul className='base_card_list'>
                            {current.map((current) => (
                            <div className='base_card_wrapper' key={current._id}>
                                <NavLink to={`${current._id}`} id={current._id} className='base_card'>
                                {current.Société}
                                </NavLink>
                                <Checkbox
                                    id={current._id}
                                    onCheckboxChange={handleCheckboxChange}
                                />
                            </div>
                            ))}
                        </ul>
                    </div>
                    <div className='base_card_row'>
                    </div>
                    <div className='base_card_row'>
                    </div>
                    
                </div>
            </div>
            <div id='pages_change'>
                { page > 1 ? <Button className='page_change' onClick={handlePrevPage}>{parseInt(page) - 1}</Button> : <div className='page_replace_turn'></div> }
                <div id='pages_indic'>{page}</div>
                { nextPage ? <Button className='page_change' onClick={handleNextPage}>{parseInt(page) + 1}</Button> : <div className='page_replace_turn'></div> }
            </div>
            { showFilters && <Filtres
                                onClose={handleCloseFilters}
                                onValidate={handleFiltersValidation}
                                partnerSide={true}
                                error={error}
                                success={success}
                            /> }
        </div>
    );
}