import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box'

import UserRoleDropdown from './utils/UserRoleDropdown';
import { post } from '../utils/backRequests';

export default function Admin({ error, success }) {
  const [ loading, setLoading ] = useState();
  const [ newUser, setNewUser ] = useState({
    name: "",
    email: "",
    password: "",
    confirm: "",
    level: ""
  });
  const [ admins, setAdmins ] = useState([]);
  const [ members, setMembers ] = useState([]);
  const [ partners, setPartners ] = useState([]);
  const [ createOpen, setCreateOpen ] = useState(false);
  const [ open, setOpen ] = useState(false);
  const [ infos, setInfos ] = useState({
    name: "",
    email: "",
    level: "",
    verified: false,
    id: ""
  });
  const [ openDeleteConfirm, setOpenDeleteConfirm ] = useState(false);
  const [ demoteConfirm, setDemoteConfirm ] = useState(false);
  const [ promoteConfirm, setPromoteConfirm ] = useState(false);
  const [ pwToConfirm, setPwToConfirm ] = useState();
  
  useEffect(() => {
    let level = 'admin';
    axios.get(`/users/getUserByRole/${level}`)
    .then(response => setAdmins(response.data))
    .catch(err => {
      error('Erreur lors du chargement des administrateurs');
    });
    level = 'member';
    axios.get(`/users/getUserByRole/${level}`)
    .then(response => setMembers(response.data))
    .catch(err => {
      error('Erreur lors du chargement des membres');
    });
    level = 'partner';
    axios.get(`/users/getUserByRole/${level}`)
    .then(response => setPartners(response.data))
    .catch(err => {
      error('Erreur lors du chargement des partenaires');
    });
  }, [])

  const handleAddAdmin = () => setCreateOpen(true);
  const handleCreateClose = () => setCreateOpen(false);
  const handleCancel = () => setCreateOpen(false);
  const handlePwInput = (e) => setPwToConfirm(e.target.value);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({
      ...newUser,
      [name]: value
    });
  };

  const handleOpen = (user) => {
    setInfos({
      name: user.name,
      email: user.email,
      level: user.level,
      verified: user.verified,
      id: user._id
    });
    setOpen(true);
  };
  const handleClose = () => {
    setInfos({
      name: "",
      email: "",
      level: "",
      verified: false,
      id: ""
    });
    setOpen(false)
  };
  
  const handleRoleSelection = (selectedRole) => {
    setNewUser({
      ...newUser,
      level: selectedRole
    });
  };

  async function submitCreateUser(event) {
    event.preventDefault();
    setLoading(true);
    // console.log("Clicked Login");
    
    const response = await post('/admin/submitnewmember', newUser);    
    const data = await response.json();

    if (response.status === 221) {
      setNewUser({
        name: "",
        email: "",
        password: "",
        confirm: "",
        level: ""
      });

        setLoading(false);
        setCreateOpen(false);
        success(response.status);
    } else {
        setLoading(false);
        error(data.error);
    }
    setNewUser({
      name: "",
      email: "",
      password: "",
      confirm: "",
      level: ""
    });

    setLoading(false);
  }

  const handleDelete = async () => {
    await axios.delete(`/users/deleteUser/${infos.id}`, { pwToConfirm })
    .then(response => {
        setOpenDeleteConfirm(false);
        handleClose();
        success(response.status);
    })
    .catch(err => {
        error(err.error);
    });
  };
  
  return (
    <div className="accounts_list">
      <h1 id='admin-title'>Utilisateurs</h1>
      <hr/>
      <h3 id='undertitle' onClick={handleAddAdmin}>Ajouter un utilisateur</h3>
      <Modal
        open={createOpen}
        onClose={handleCreateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="create_from_admin_content">
          <form autoComplete='off' id='create-user-form'>
            <h1 id='user-create-modal-title'>Ajouter un compte</h1>
            <div className='form-entries'>
              <label>
                <p id='input-title'>Nom</p>
                <input className="long-box" type="text" id='email' name='name' value={newUser.name} onChange={handleInputChange}/>
              </label>
              <label>
                <p id='input-title'>Email</p>
                <input className="long-box" type="text" id='email' name='name' value={newUser.email} onChange={handleInputChange}/>
              </label>
              <div id='passwords'>
                <label id='pass-form'>
                  <p id='input-title'>Mot de passe</p>
                  <input className="long-box" type="password" id='password' name='name' value={newUser.password} onChange={handleInputChange}/>
                </label>
                <label id='pass-form'>
                  <p id='input-title'>Confirmation</p>
                  <input className="long-box" type="password" id='password' name='name' value={newUser.confirm} onChange={handleInputChange}/>
                </label>
              </div>
              <UserRoleDropdown onSelectRole={handleRoleSelection} />
            </div>
            <div>
              <Button onClick={handleCancel} className="create-user-button" id='cancel'>Annuler</Button>
              {loading ? (
                  <button id='create-user-button'>Chargement...</button>
              ) : (
                  <Button onClick={submitCreateUser} className="create-user-button" id='connexion'>Valider</Button>
                  )}
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id='info_member_modal'
        >
          <Box className='wrapper info-member'>
            <div>
              <span className='bigger-info'>Nom : </span><span className='gray-info'>{infos.name}</span>
            </div>
            <div>
              <span className='bigger-info'>Email : </span><span className='gray-info'>{infos.email}</span>
            </div>
            <div>
              <span className='bigger-info'>Role : </span><span className='gray-info'>{infos.level}</span>
            </div>
            <div>
              <span className='bigger-info'>Vérifié : </span><span className='gray-info'>{infos.verified ? "Oui" : "Non"}</span>
            </div>
            <div className='manage-accounts-buttons'>
              { infos.level === "admin" ? (<Button className='save' onClick={() => setDemoteConfirm(true)}>Passer membre</Button>) : (<Button className='save' onClick={() => setPromoteConfirm(true)}>Passer admin</Button>) }
              <Button className='cancel delete-account' onClick={() => setOpenDeleteConfirm(true)}>Supprimer</Button>
            </div>
          </Box>
      </Modal>
      <Modal open={openDeleteConfirm}>
        <div className="wrapper confirm-users-boxes">
            <h2>Voulez-vous vraiment supprimer cet utilisateur ?</h2>
            <input className='confirm-user-action-pw input' placeholder='Confirmez votre mot de passe' type="text" onChange={handlePwInput}/>
            <div className="manage-accounts-buttons2">
                <Button className="save cancel-white" onClick={() => setOpenDeleteConfirm(false)}>Annuler</Button>
                <Button className="cancel delete-account" onClick={() => setOpenDeleteConfirm(false)}>Supprimer</Button>
            </div>
        </div>
      </Modal>
      <Modal open={demoteConfirm}>
        <div className="wrapper confirm-users-boxes">
            <h2>Voulez-vous vraiment passer {infos.name} membre ?</h2>
            <input className='confirm-user-action-pw input' placeholder='Confirmez votre mot de passe' type="text" onChange={handlePwInput}/>
            <div className="manage-accounts-buttons2">
                <Button className="cancel" onClick={() => setDemoteConfirm(false)}>Annuler</Button>
                <Button className="save delete-account" onClick={() => setDemoteConfirm(false)}>Valider</Button>
            </div>
        </div>
      </Modal>
      <Modal open={promoteConfirm}>
        <div className="wrapper confirm-users-boxes">
            <h2>Voulez-vous vraiment promouvoir {infos.name} administrateur ?</h2>
            <input className='confirm-user-action-pw-admin input' placeholder='Confirmez votre mot de passe' type="text" onChange={handlePwInput}/>
            <div className="manage-accounts-buttons2">
                <Button className="cancel" onClick={() => setPromoteConfirm(false)}>Annuler</Button>
                <Button className="save delete-account" onClick={() => setPromoteConfirm(false)}>Valider</Button>
            </div>
        </div>
      </Modal>
      <div className="users_recap">
        <div className="admin">
          <h1>Administrateurs</h1>
          <hr/>
          
          <ul id='admin_list'>
          {admins.map((admin) => (
              <Button id='usercard' onClick={() => handleOpen(admin)}>
                {admin.name}
              </Button>
          ))}
        </ul>
        </div>
        <div className="members">
          <h1>Membres</h1>
          <hr/>
          <ul id='member_list'>
          {members.map((member) => (
              <Button id='usercard' onClick={() => handleOpen(member)}>
                {member.name}
              </Button>
          ))}
        </ul>
        </div>
        <div className="partners">
          <h1>Partenaires</h1>
          <hr/>
          <ul id='partner_list'>
          {partners.map((partner) => (
            <Button id='usercard' onClick={() => handleOpen(partner)}>
                {partner.name}
              </Button>
          ))}
        </ul>
        </div>
      </div>
    </div>
  )
}