import React from "react";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }

  handleCheckboxChange = () => {
    this.setState(prevState => ({
      checked: !prevState.checked
    }));
  };

  render() {
    const { checked } = this.state;
    const { label } = this.props;

    return (
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={this.handleCheckboxChange}
        />
        {label}
      </label>
    );
  }
}

export default Checkbox;
