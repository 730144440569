import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';
import Select from 'react-dropdown-select';
import axios from "axios";

export default function ActionCreate({ onClose, partnerId, partnerSide, error, success }) {
    const [selectedDate, setSelectedDate] = useState(null);
    const [operations, setOperations] = useState([]);
    const [operation, setOperation] = useState([]);
    const [title, setTitle] = useState();
    const [contact, setContact] = useState();
    const [subject, setSubject] = useState();

    useEffect(() => {
        const fetchOpsPartner = async () => {
            axios.get(`http://localhost:9000/api/getOperationsFor/${partnerId}`)
            .then(response => {
                const operations_ = response.data.map(ops => {
                    return { id: ops._id, name: ops.Nom };
                });
                setOperations(operations_);
            })
            .catch(err => {
                error(err.error);
            });
        };
        const fetchOpsClient = async () => {
            axios.get(`http://localhost:9000/api/getOperationsForClient/${partnerId}`)
            .then(response => {
                const operations_ = response.data.map(ops => {
                    return { id: ops._id, name: ops.Nom };
                });
                setOperations(operations_);
            })
            .catch(err => {
                error(err.error);
            });
        };

        if (partnerSide) {
            fetchOpsPartner();
        } else {
            fetchOpsClient();
        }

    }, [operations]);

    const handleDateChange = (date) => { setSelectedDate(date) };
    const handleTitleChange = (e) => { setTitle(e.target.value) };
    const handleContactChange = (e) => { setContact(e.target.value) };
    const handleSubjectChange = (e) => { setSubject(e.target.value) };

    const handleValidate = async () => {
        let ops = [];
        operation.forEach(op => {
            ops.push({ "opération": op.id , "nom": op.name });
        });

        const action = {
            "Nom": title,
            "Date": selectedDate,
            "Entité": partnerId,
            "partner": partnerSide,
            "Contact": contact,
            "Opérations": ops,
            "Notes": subject,
            "Ressources": [],
        };
        await axios.post('/api/addAction', action)
        .then(response => {
            success(response.status);
            onClose();
        })
        .catch(err => {
            error(err.error);
        });
    }

    return (
        <div className="wrapper" id="create_action_wrapper">
            <div id="create_action_title_wrapper">
                <h1 id="create_action_title">Créer une action</h1>
                <hr/>
            </div>
            <div id="create_action_form_wrapper">
                <div id="labels">
                    <label htmlFor="title">Titre :</label>
                    <label htmlFor="contact">Contact :</label>
                    <label htmlFor="subject" id="actionlabelsubject">Sujet :</label>
                    <label htmlFor="operation">Opération :</label>
                </div>
                <div id="inputs">
                    <form className="form" id="create_action_givetitle">
                        <input type="text" className="input" id="actionformtitle" name="title" onChange={handleTitleChange} required/>
                    </form>
                    <form className="form" id="create_action_givecontact">
                        <input type="text" className="input" id="actionformcontact" name="contact" onChange={handleContactChange} required/>
                    </form>
                    <form className="form" id="create_action_givesubject">
                        <textarea rows={6} type="text" className="input" id="actionformsubject" name="subject" onChange={handleSubjectChange} required/>
                    </form>
                    <Select
                        name='select'
                        className='input'
                        id='actionformopchoice'
                        options={operations}
                        labelField="name"
                        valueField="id"
                        onChange={(value) => setOperation(value)}
                    />
                </div>
            </div>
            <div id="create_action_buttons">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker id="actionformdatechoice" value={selectedDate} onChange={handleDateChange} />
                </LocalizationProvider>
                <Button className="cancel" id="cancel_create_action" onClick={onClose}>Annuler</Button>
                <Button className="save" id="save_create_action" onClick={handleValidate}>Enregistrer</Button>
            </div>
        </div>
    )
}