import React, { useState } from "react";
import Button from '@mui/material/Button';

export default function ContactCreate({ onClose, onValidate }) {
    const [contact, setContact] = useState({
        "Civilité": "",
        "Nom du responsable": "",
        "Prénom du responsable": "",
        "Téléphone fixe": "",
        "Portable": "",
        "Email": "",
        "Fonction": ""
    });

    const handleChange = (e) => {
        setContact({
            ...contact,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="wrapper contact-creation-box">
            <div className="contact-creation-header">
                <h2 className="contact-creation-title">Créer un contact</h2>
                <hr className="contact-creation-hr"/>
            </div>
            <div className="contact-creation-body">
                <form className="contact-creation-form">
                    <div className="contact-creation-group">
                        <label className="contact-creation-label">Civilité</label>
                        <input className="contact-creation-input input" type="text" name="Civilité" onChange={handleChange}/>
                    </div>
                    <div className="contact-creation-group">
                        <label className="contact-creation-label">Nom</label>
                        <input className="contact-creation-input input" type="text" name="Nom du responsable" onChange={handleChange}/>
                    </div>
                    <div className="contact-creation-group">
                        <label className="contact-creation-label">Prénom</label>
                        <input className="contact-creation-input input" type="text" name="Prénom du responsable"onChange={handleChange} />
                    </div>
                    <div className="contact-creation-group">
                        <label className="contact-creation-label">Téléphone fixe</label>
                        <input className="contact-creation-input input" type="text" name="Téléphone fixe" onChange={handleChange}/>
                    </div>
                    <div className="contact-creation-group">
                        <label className="contact-creation-label">Portable</label>
                        <input className="contact-creation-input input" type="text" name="Portable" onChange={handleChange}/>
                    </div>
                    <div className="contact-creation-group">
                        <label className="contact-creation-label">Email</label>
                        <input className="contact-creation-input input" type="text" name="Email" onChange={handleChange}/>
                    </div>
                    <div className="contact-creation-group">
                        <label className="contact-creation-label">Fonction</label>
                        <input className="contact-creation-input input" type="text" name="Fonction" onChange={handleChange}/>
                    </div>
                </form>
            </div>
            <div className="contact-creation-buttons">
                <Button className="cancel contact-creation-cancel" onClick={onClose}>Annuler</Button>
                <Button className="save contact-creation-validate" onClick={() => onValidate(contact)}>Valider</Button>
            </div>
        </div>
    )
}