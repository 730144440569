async function post(url, data) {
    const response = await fetch(`${url}`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return response;
}

async function get(url) {
    const response = await fetch(`${url}`, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    });
    return response;
}

module.exports = { post, get };