import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import useAuth from '../utils/AuthContext';
import { post } from '../utils/backRequests';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Account({ error, success }) {
    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState("11vh");
    const [display, setDisplay] = useState("none");
    const [loading, setLoading] = useState();
    const { userID, email, name } = useAuth();
    const navigate = useNavigate();
    const { checkAuthStatus } = useAuth();

    const handleExpandClick = () => {
        setExpanded(!expanded);
        if (expanded) {
            setHeight("11vh");
            setDisplay("none");
        } else {
            setHeight("28vh");
            setDisplay("block");
        }
    };

    async function handleLogout(event) {
        event.preventDefault();
        setLoading(true);
        
        try {
            const response = await axios.post('/login/logout/signout');
            // console.log(response);
            success(response.status);
            setLoading(false);
            navigate('/login');
            await checkAuthStatus();
        } catch(e) {
            error(e.error);
            setLoading(false);
        }
    }

    async function handleChangePassword(event) {
        event.preventDefault();
        setLoading(true);

        const response = await post('/login/forgot_password/', { email });
        const data = await response.json();

        if (response.status === 'ok') {
            setLoading(false);
            navigate("/reset", {state: {isLogged: true}});
        } else {
            setLoading(false);
            error(data.error);
        }
    }

  return (
    <>
    
    <Card className="card" style={{ height }}>
        <div className="card_header">
            <CardHeader
                title={
                    <Typography variant="h5" component="div" style={{ fontFamily: 'Futura', fontSize: "3.5vh", textAlign: "right" }}>
                        Bienvenue
                    </Typography>
                }
                subheader={
                    <Typography style={{ fontFamily: "Futura", color: "rgba(32, 32, 32, 0.50)", fontSize: "2.5vh", textAlign: "right" }}>
                        {name}
                    </Typography>
                }
            />
            <div id='button_expand'>
                <CardActions>
                    <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="expand"
                    >
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/2048px-Default_pfp.svg.png" id="whatsapp" />
                    </ExpandMore>
                </CardActions>
            </div>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent id='card_buttons'>
                <Button id='change_pass' onClick={handleChangePassword} style={{ display }}>Changer le mot de passe</Button>
                <Button id='deco' onClick={handleLogout} style={{ display }}>Se déconnecter</Button>
            </CardContent>
        </Collapse>
        </Card>
    </>
  );
}
