import React, { useState } from 'react';

const UserRoleDropdown = ({ onSelectRole }) => {
  const [selectedRole, setSelectedRole] = useState('');

  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    setSelectedRole(newRole);
    onSelectRole(newRole);
  };

  return (
    <div id='user-role-drop'>
      <label id='input-title-dropdown' htmlFor="userRole">Role</label>
      <select id="userRole" value={selectedRole} onChange={handleRoleChange}>
        <option id='drop-option' value="partner">Partenaire</option>
        <option id='drop-option' value="member">Membre</option>
        <option id='drop-option' value="admin">Administrateur</option>
      </select>
    </div>
  );
};

export default UserRoleDropdown;
