import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import { post } from '../utils/backRequests';
import NotifBad from './utils/NotifBad';

export default function Forgot_Password({ error, success }) {
    const [email_forgot, setEmailForgot] = useState();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    async function submitLogin(event) {
        event.preventDefault();
        setLoading(true);

        
        const email = email_forgot;
        
        const response = await post('/login/forgot_password/', { email });
        const data = await response.json();
        
        if (response.status === 'ok') {
            setLoading(false);
            navigate("/reset", {state: {isLogged : false, email_forgot}});
        } else {
            setLoading(false);
            error(data.error);
        }
    }

    const handleEmailInput = (e) => setEmailForgot(e.target.value);

    return(
        <div className="login-wrapper">
            <h1>Demande de réinitialisation de mot de passe</h1>
            <form autoComplete='off'>
                <label>
                    <p>Email</p>
                    <input type="text" id='email' onChange={handleEmailInput}/>
                </label>
                <div>
                    {loading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                        <button onClick={submitLogin}>Se connecter</button>
                    )}
                </div>
            </form>
        </div>
    )
};